import React, { useState, useEffect } from "react";
import {
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  createTheme,
  ThemeProvider,
  TableHead,
  TextField,
  IconButton,
  MenuItem,
  Popover,
  Select,
  FormControl,
  InputLabel,
  Paper,
  Box,
  Chip,
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import {
  aws_url,
  admintreatmentscreate,
  adminDepartmentgetAll,
  admintreatmentsgetAll,
  admintreatmentsUpdate,
  admintreatmentsDelete,
  // adminDepartmentDelete, // Enable delete service
} from "../../Service/Services";
import ImageUpload from "../../DoctorPannel/DoctorComponents/ImageUpload";

const TreatmentsPage = () => {
  const procedureOptions = [
    "Pain relief",
    "Mobility restoration",
    "Tumor removal",
    "Vision correction",
    "Infection removal",
    "Heart function restoration",
    "Cosmetic enhancement",
    "Organ transplant",
    "Symptom management",
    "Tissue repair or reconstruction",
  ];
  const procedurePurposeOptions=[
    "Surgical",
    "Minimally Invasive",
    "Outpatient",
    "Laparoscopic",
    "Robotic-assisted",
    "Open surgery",
    "Non-surgical (e.g., injection therapy)",
    "Endoscopic",
    "Interventional radiology",
    "Laser-based procedure",
  ];
  const technology=[
    "Robotic-assisted surgery",
    "Laparoscopy",
    "Laser surgery",
    "Endoscopy",
    "Imaging guidance (e.g., MRI, CT)",
    "3D printing (e.g., custom implants)",
    "Virtual reality for surgical planning",
    "Cryotherapy",
    "Radiofrequency ablation",
    "Genetic testing for precision treatments",               
  ];
  const followUpList = [
    "Physical therapy sessions",
    "Home exercises",
    "Regular check-ups",
    "Imaging tests (e.g., X-rays, MRI)",
    "Wound monitoring",
    "Removal of sutures or staples",
    "Adjustments in medications",
    "Occupational therapy (if needed)",
    "Cardiac rehabilitation (for heart procedures)",
    "Psychosocial support",                   
  ];
  const insuranceList = [
    "Covered by most health insurance plans",
     "Prior authorization required",
     "Out-of-pocket costs for co-pays",
     "Government health coverage (e.g., Medicare, Medicaid)",
     "High-deductible health plans (HDHP) considerations",
     "No coverage for cosmetic procedures",
     "Partial coverage based on procedure type",
     "International medical tourism packages",
     "Employer-sponsored health plans",
     "Supplemental insurance options",
  ];
  const successList = [
    "Varies by patient's condition and procedure",
    "High success rate with experienced surgeons",
    "Success influenced by post-surgical care adherence",
    "Long-term success rates",
    "Higher success with minimally invasive techniques",
    "Success may depend on coexisting medical conditions",
  ];
  const [showPopup, setShowPopup] = useState(false);
  const [showeditPopup, setShoweditPopup] = useState(false);
  const [open, setOpen] = useState(null);
  const [editid, setEditid] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [treatmentsData, settreatmentsData] = useState([]);
  const [selectedProcedureOptions, setSelectedProcedureOptions] = useState([]);
  const [procOptions, setProcOptions] = useState(procedureOptions);
  const [selectedProcedurePurposeOptions, setSelectedProcedurePurposeOptions] = useState([]);
  const [procPurposeOptions, setProcPurposeOptions] = useState(procedurePurposeOptions);
  const [showInsuranceOptions, setShowInsuranceOptions] = useState(false);
  const [selectedTechnology, setSelectedTechnology ] = useState([]);
  const [techOptions, setTechOptions ] = useState(technology);
  const [selectedFollowUp, setSelectedFollowUp ] = useState([]);
  const [followUpOptions, setFollowUpOptions ] = useState(followUpList);
  const [selectedInsuranceOptions, setSelectedInsuranceOptions ] = useState([]);
  const [selectedSuccess, setSelectedSuccess ] =useState([]);
  const [successOptions, setSuccessOptions ] =useState(successList);
  // const [Dummy, setDummy] = useState("Not Filled");
  const [form, setForm] = useState({
    Upload_Icon: "",
    Department: "",
    Treatment_Name: "",
    Procedure_Type: [],
    Purpose_of_Procedure: [],
    Visiting_Places:"",
    duration_of_procedure: {
      time: "", // Default to 1 minute
      unit: "minutes", // Default unit
    },
    consultation_duration:false,
    Anesthesia_Type: "",
    Risks_and_Complications: "",
    pre_procedure_preparation: "",
    post_procedure_care: "",
    Recovery_Time: "",
    estimated_cost_range: "",
    eligibility: "",
    alternative_treatments: "",
    hospital_stay: {
      hospital_Name: "",
      time: "", // Default to 1 hour
      unit: "hours", // Default unit
    },
    consultation_stay:false,
    technology_used: [],
    success_rate: {
      who_much: [], // Consider using a number here
      max_days: "",
      Minimum_days: "",
    },
    follow_up_care: [],
    possible_side_effects: "",
    contraindications: "",
    recovery_milestones: {
      recovery_milestone: "",
      min_days: 1,
      max_days: 10,
    },
    insurance_coverage: false,
    insurance_type: [],
    package: "",
    minprice: 0, // Ensure validation for non-negative numbers
    maxprice: 0, // Ensure validation for non-negative numbers
  });

  

  // const handleChange = (event, value) => {
  //   if (value.length <= 3) {
  //     setSelectedProcedureOptions(value);
  //     if(!procedureOptions.includes(value)){
  //       procedureOptions.push(value);
  //     }
  //   }
  // };

  const handleNewOption = (event, newValue) => {
    const lastTypedOption = newValue[newValue.length - 1];
    if (newValue.length <= 3) {
      setSelectedProcedureOptions(newValue);
    
    if (!procOptions.includes(lastTypedOption)) {
      procedureOptions.push(lastTypedOption);
      setProcOptions([...procOptions, lastTypedOption]); // Add new custom option
      
    }
  
    const syntheticEvent = {
      target: {
        name: 'Procedure_Type',  // Replace with your form field name
        value: newValue,
      },
    };
    forProfileChange(syntheticEvent);
  }
  };

  const handleNewProcedurePurposeOption = (event, newValue) => {
    const lastTypedOption = newValue[newValue.length - 1];
    if (newValue.length <= 3) {
      setSelectedProcedurePurposeOptions(newValue);
    
    if (!procPurposeOptions.includes(lastTypedOption)) {
      procedurePurposeOptions.push(lastTypedOption);
      setProcPurposeOptions([...procPurposeOptions, lastTypedOption]); // Add new custom option
      
    }
    const syntheticEvent = {
      target: {
        name: 'Purpose_of_Procedure',  // Replace with your form field name
        value: newValue,
      },
    };
    forProfileChange(syntheticEvent);
  }
  };

  const handleNewFollowUpOption = (event, newValue) => {
    const lastTypedOption = newValue[newValue.length - 1];
    if (newValue.length <= 3) {
      setSelectedFollowUp(newValue);
    
    if (!followUpOptions.includes(lastTypedOption)) {
      followUpList.push(lastTypedOption);
      setFollowUpOptions([...followUpOptions, lastTypedOption]); // Add new custom option
      
    }
    const syntheticEvent = {
      target: {
        name: 'follow_up_care',  // Replace with your form field name
        value: newValue,
      },
    };
    forProfileChange(syntheticEvent);
  }
  };

  const handleNewTechOption = (event, newValue) => {
    const lastTypedOption = newValue[newValue.length - 1];
    if (newValue.length <= 3) {
      setSelectedTechnology(newValue);
    
    if (!techOptions.includes(lastTypedOption)) {
      technology.push(lastTypedOption);
      setTechOptions([...techOptions, lastTypedOption]); // Add new custom option
      
    }
    const syntheticEvent = {
      target: {
        name: 'technology_used',  // Replace with your form field name
        value: newValue,
      },
    };
    forProfileChange(syntheticEvent);
  }
  };

  const handleNewInsuranceOption= (event, newValue) => {
    if (newValue.length <= 3) {
      setSelectedInsuranceOptions(newValue);
    const syntheticEvent = {
      target: {
        name: 'insurance_type',  // Replace with your form field name
        value: newValue,
      },
    };
    forProfileChange(syntheticEvent);
  }
  };

  const handleNewSuccessUpOption = (event, newValue) => {
    const lastTypedOption = newValue[newValue.length - 1];
    if (newValue.length <= 3) {
      setSelectedSuccess(newValue);
    
    if (!successOptions.includes(lastTypedOption)) {
      successList.push(lastTypedOption);
      setSuccessOptions([...successOptions, lastTypedOption]); // Add new custom option
      
    }
    const syntheticEvent = {
      target: {
        name: 'success_rate.who_much',  // Replace with your form field name
        value: newValue,
      },
    };
    forProfileChange(syntheticEvent);
  }
  };

  

  // console.log(form);
  // console.log(treatmentsData);

  // Fetch all departments
  const Departmentget = async () => {
    try {
      const response = await adminDepartmentgetAll();
      const departmentData = response.data?.Data;
      setDepartmentData(departmentData);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Failed to get department.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while fetching departments:", error);
    }
  };

  // Initial fetch of departments

  const admintreatmentsget = async () => {
    try {
      const response = await admintreatmentsgetAll();
      const treatmentsData = response.data?.data || []; // Use 'data' key and default to an empty array
      settreatmentsData(treatmentsData);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Failed to get treatments.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while fetching treatments:", error);
    }
  };

  useEffect(() => {
    admintreatmentsget();
    Departmentget();
  }, []);
  // Toggle popup
  const togglePopup = () => {
    setShowPopup(true);
  };

  // Handle profile field changes
  const forProfileChange = (event) => {
    const { name, value } = event.target;

    // If the name contains a dot, we need to handle nested state
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setForm((prevForm) => ({
        ...prevForm,
        [parent]: {
          ...prevForm[parent],
          [child]: value,
        },
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const forSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    // Validate form inputs before sending the request
    if (!form.Upload_Icon || !form.Department || !form.Treatment_Name) {
      toast.error("Please fill in all required fields.");
      return; // Exit early if validation fails
    }

    try {
      let response;

      // Check if we are editing or creating a treatment
      if (editid) {
        // Updating an existing department
        response = await admintreatmentsUpdate({
          _id: editid,
          ...form, // Spread operator to include all form fields
          Status: "Filled", // Update the status to "Filled"
        });
      } else {
        // Creating a new treatment
        response = await admintreatmentscreate(form); // Pass form object directly
      }

      // Check response status and display appropriate messages
      if (response && response.status === 200) {
        toast.success(response.data.message);
        Departmentget(); // Refresh the department list
        admintreatmentsget(); // Refresh the treatment list
        setShowPopup(false);
        setShoweditPopup(false);
        // Clear the form
        setForm({ Upload_Icon: "", Department: "", Treatment_Name: "" });
        setEditid(null); // Reset edit ID after successful update
      } else {
        toast.error("Failed to save treatments.");
      }
    } catch (error) {
      // Improved error handling
      const errorMessage = error.response
        ? error.response.data.message
        : "An unexpected error occurred.";
      toast.error(errorMessage);
      console.error("Error while submitting:", error);
    }
    // console.log(form); // For debugging
  };

  // Handle edit action
  const handleEditTreatment = (id) => {
    const treatmentToEdit = treatmentsData.find((d) => d._id === id);

    if (treatmentToEdit) {
      // Ensure treatmentToEdit exists
      setForm({
        Upload_Icon: treatmentToEdit.Upload_Icon,
        Department: treatmentToEdit.Department,
        Treatment_Name: treatmentToEdit.Treatment_Name,
        Procedure_Type: treatmentToEdit.Procedure_Type,
        Purpose_of_Procedure: treatmentToEdit.Purpose_of_Procedure,
        Visiting_Places: treatmentToEdit.Visiting_Places,
        Anesthesia_Type: treatmentToEdit.Anesthesia_Type,
        Recovery_Time: treatmentToEdit.Recovery_Time,
        Risks_and_Complications: treatmentToEdit.Risks_and_Complications,
        pre_procedure_preparation: treatmentToEdit.pre_procedure_preparation,
        post_procedure_care: treatmentToEdit.post_procedure_care,
        estimated_cost_range: treatmentToEdit.estimated_cost_range,
        eligibility: treatmentToEdit.eligibility,
        alternative_treatments: treatmentToEdit.alternative_treatments,
        technology_used: treatmentToEdit.technology_used,
        follow_up_care: treatmentToEdit.follow_up_care,
        possible_side_effects: treatmentToEdit.possible_side_effects,
        contraindications: treatmentToEdit.contraindications,
        insurance_coverage: treatmentToEdit.insurance_coverage,
        insurance_type: treatmentToEdit.insurance_type,
        package: treatmentToEdit.package,
        minprice: treatmentToEdit.minprice,
        maxprice: treatmentToEdit.maxprice,
        duration_of_procedure: {
          time: treatmentToEdit.duration_of_procedure?.time || "", // Provide a default value if undefined
          unit: treatmentToEdit.duration_of_procedure?.unit || "", // Provide a default value if undefined
        },
        consultation_duration :treatmentToEdit.consultation_duration,
        hospital_stay: {
          hospital_Name: treatmentToEdit.hospital_stay?.hospital_Name || "", // Provide a default value if undefined
          time: treatmentToEdit.hospital_stay?.time || "", // Provide a default value if undefined
          unit: treatmentToEdit.hospital_stay?.unit || "", // Provide a default value if undefined
        },
        consultation_stay : treatmentToEdit.consultation_stay,
        success_rate: {
          who_much: treatmentToEdit.success_rate?.who_much || "", // Provide a default value if undefined
          max_days: treatmentToEdit.success_rate?.max_days || "", // Provide a default value if undefined
          Minimum_days: treatmentToEdit.success_rate?.Minimum_days || "", // Provide a default value if undefined
        },
        recovery_milestones: {
          recovery_milestone:
            treatmentToEdit.recovery_milestones?.recovery_milestone || "", // Provide a default value if undefined
          min_days: treatmentToEdit.recovery_milestones?.min_days || "", // Provide a default value if undefined
          max_days: treatmentToEdit.recovery_milestones?.max_days || "", // Provide a default value if undefined
        },
        Status: treatmentToEdit.Status,
      });
      setEditid(id); // Save the ID of the item being edited
      // togglePopup(); // Open the edit popup/modal
    } else {
      console.error("Treatment not found with the provided ID");
    }
  };

  // Handle delete action
  const handleDeleteUser = async (id) => {
    if (!id) return;
    try {
      const response = await admintreatmentsDelete(id);
      if (response.status === 200) {
        toast.success(response.data.message);
        Departmentget(); // Refresh department list
        admintreatmentsget(); // Refresh treatment list
      } else {
        toast.error("Failed to delete treatment.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while deleting treatment:", error);
    }
  };

  // Handle menu actions
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setEditid(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <React.Fragment>
      <ThemeProvider
        theme={createTheme({
          /* Customize your theme here */
        })}
      >
        <Container>
          {/* Header */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Manage Treatments
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setEditid(null); // Reset edit ID for new department creation
                setForm({ departmentName: "", departmentImage: "" }); // Reset form
                togglePopup();
              }}
            >
              New Treatment
            </Button>
          </Stack>

          {/* Department List */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>DEPARTMENT NAME</TableCell>
                  <TableCell>TREATMENT NAME</TableCell>
                  <TableCell>Dummy</TableCell>
                  <TableCell>IMAGE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {treatmentsData.length > 0 ? (
                  treatmentsData.map((treatments) => (
                    <TableRow key={treatments._id}>
                      <TableCell>{treatments._id}</TableCell>
                      <TableCell>{treatments.Department}</TableCell>
                      <TableCell>{treatments.Treatment_Name}</TableCell>
                      <TableCell>{treatments.Status}</TableCell>
                      <TableCell>
                        <img
                          src={
                            treatments.Upload_Icon &&
                            treatments.Upload_Icon.startsWith("http")
                              ? treatments.Upload_Icon
                              : treatments.Upload_Icon
                              ? `${aws_url}/${treatments.Upload_Icon}`
                              : "path/to/default/image.jpg" // Fallback image
                          }
                          alt={treatments.Upload_Icon || "Default Alt Text"}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(e) => handleOpenMenu(e, treatments._id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No treatments found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Popup for Creating/Editing */}
          {showPopup && (
            <form onSubmit={forSubmit}>
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white shadow-lg rounded-lg p-6">
                  <FormControl fullWidth>
                    <InputLabel id="estimated-cost-range-label">
                      Department
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Department Name"
                      required
                      fullWidth
                      value={form.Department} // Use the correct state value
                      autoComplete="off"
                      name="Department" // Ensure this matches the form state
                      onChange={forProfileChange}
                      placeholder="Department Name*"
                    >
                      {departmentData.map((department) => (
                        <MenuItem
                          key={department._id}
                          placeholder="Department Name*"
                          value={department.departmentName} // This will set the Department state when selected
                        >
                          {department.departmentName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Stack direction="column" spacing={2} mt={2}>
                    <TextField
                      required
                      fullWidth
                      value={form.Treatment_Name}
                      autoComplete="off"
                      name="Treatment_Name"
                      onChange={forProfileChange}
                      placeholder="Treatment Name*"
                    />

                    <ImageUpload
                      setForm={setForm}
                      fieldname={"Upload_Icon"} // Use correct field name here
                      imageurl={
                        form.Upload_Icon ? `${aws_url}/${form.Upload_Icon}` : ``
                      }
                    />
                  </Stack>

                  <div className="flex items-center justify-between">
                    <button
                      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                      onClick={() => {
                        setShowPopup(false);
                      }}
                      type="button"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          {/* Popover Menu for Edit/Delete */}
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
          >
            <MenuItem
              onClick={() => {
                handleEditTreatment(editid);
                handleCloseMenu();
                setShoweditPopup(true);
              }}
            >
              <EditIcon />
              Edit
            </MenuItem>

            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                handleDeleteUser(editid);
                handleCloseMenu();
              }}
            >
              <DeleteIcon />
              Delete
            </MenuItem>
          </Popover>

          {showeditPopup && (
            <form onSubmit={forSubmit}>
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white shadow-lg rounded-lg p-6 w-[900px] max-h-[90vh] overflow-y-auto z-50">
                  <Container>
                    <Stack spacing={4}>
                      {/* Upload Icon Section */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Upload Icon
                        </Typography>
                        <FormControl fullWidth component={Stack} spacing={2}>
                          <img
                            src={
                              form.Upload_Icon
                                ? `${aws_url}/${form.Upload_Icon}`
                                : ``
                            }
                            alt="Upload_Icon"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "10%",
                            }}
                          />
                        </FormControl>
                      </Paper>
                      {/* Form Fields */}
                      <Paper elevation={3} className="p-3">
                        <Typography variant="h6" gutterBottom>
                          General Information
                        </Typography>
                        {[
                          {
                            label: "Departments",
                            name: "Department",
                            editable: false,
                          },
                          {
                            label: "Treatment Name",
                            name: "Treatment_Name",
                            editable: false,
                          },

                          // { label: "Package", name: "package", editable: true },
                        ].map((field) => (
                          <Box key={field.name} my={2} mx={2}>
                            <FormControl fullWidth>
                              <TextField
                                label={field.label}
                                required
                                multiline={field.multiline || false}
                                fullWidth
                                value={form[field.name]}
                                autoComplete="off"
                                name={field.name}
                                onChange={forProfileChange}
                                InputProps={{
                                  readOnly: !field.editable, // Make the field read-only based on the editable property
                                }}
                              />
                            </FormControl>
                          </Box>
                        ))}

                        <Box mx={2} my={2}>
                        <FormControl fullWidth>
                          <Autocomplete
                          className="mb-3 "
                          multiple
                          freeSolo
                          options={procOptions}
                          value={form.Procedure_Type}
                          onChange={handleNewOption}
                          renderInput={(params) => (
                          <TextField {...params} label="Procedure Type" placeholder="Type or select" />
                            )}
                         renderTags={(val, getTagProps) =>
                          val.map((option, index) => (
                            <Chip
                           key={option}
                           label={option}
                           {...getTagProps({ index })}
                           color="primary"
                         />
                        ))
                      }
                     getOptionLabel={(option) => option}
                     renderOption={(props, option, { selected }) => (
                      <li {...props}>
                      <Checkbox
                      checked={selected}
                      />
                      <ListItemText primary={option} />
                      </li>
                      )}
                     />
                      </FormControl>
                          {/* <FormControl fullWidth>
                            <InputLabel id="procedure-type-label">
                              Procedure Type
                            </InputLabel>
                            <Select
                              className="mb-3 "
                              labelId="procedure-type-label"
                              label="Procedure Type"
                              required
                              fullWidth
                              value={form.Procedure_Type}
                              name="Procedure_Type"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Pain relief">
                                Pain relief
                              </MenuItem>
                              <MenuItem value="Mobility restoration">
                                Mobility restoration
                              </MenuItem>
                              <MenuItem value="Tumor removal">
                                Tumor removal
                              </MenuItem>
                              <MenuItem value="Vision correction">
                                Vision correction
                              </MenuItem>
                              <MenuItem value="Infection removal">
                                Infection removal
                              </MenuItem>
                              <MenuItem value="Heart function restoration">
                                Heart function restoration
                              </MenuItem>
                              <MenuItem value="Cosmetic enhancement">
                                Cosmetic enhancement
                              </MenuItem>
                              <MenuItem value="Organ transplant">
                                Organ transplant
                              </MenuItem>
                              <MenuItem value="Symptom management">
                                Symptom management
                              </MenuItem>
                              <MenuItem value="Tissue repair or reconstruction">
                                Tissue repair or reconstruction
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                          <FormControl fullWidth>
                          <Autocomplete
                          className="mb-3 "
                          multiple
                          freeSolo
                          options={procPurposeOptions}
                          value={form.Purpose_of_Procedure}
                          onChange={handleNewProcedurePurposeOption}
                          renderInput={(params) => (
                          <TextField {...params} label="Purpose of Procedure" placeholder="Type or select" />
                            )}
                         renderTags={(val, getTagProps) =>
                          val.map((option, index) => (
                            <Chip
                           key={option}
                           label={option}
                           {...getTagProps({ index })}
                           color="primary"
                         />
                        ))
                      }
                     getOptionLabel={(option) => option}
                     renderOption={(props, option, { selected }) => (
                      <li {...props}>
                      <Checkbox
                      checked={selected}
                      />
                      <ListItemText primary={option} />
                      </li>
                      )}
                     />
                      </FormControl>
                          {/* <FormControl fullWidth>
                            <InputLabel id="purpose-of-procedure-label">
                              Purpose of Procedure
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="purpose-of-procedure-label"
                              label="Purpose of Procedure"
                              required
                              fullWidth
                              value={form.Purpose_of_Procedure}
                              name="Purpose_of_Procedure"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Surgical">Surgical</MenuItem>
                              <MenuItem value="Minimally Invasive">
                                Minimally Invasive
                              </MenuItem>
                              <MenuItem value="Outpatient">Outpatient</MenuItem>
                              <MenuItem value="Laparoscopic">
                                Laparoscopic
                              </MenuItem>
                              <MenuItem value="Robotic-assisted">
                                Robotic-assisted
                              </MenuItem>
                              <MenuItem value="Open surgery">
                                Open surgery
                              </MenuItem>
                              <MenuItem value="Non-surgical (e.g., injection therapy)">
                                Non-surgical (e.g., injection therapy)
                              </MenuItem>
                              <MenuItem value="Endoscopic">Endoscopic</MenuItem>
                              <MenuItem value="Interventional radiology">
                                Interventional radiology
                              </MenuItem>
                              <MenuItem value="Laser-based procedure">
                                Laser-based procedure
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                          
                        </Box>
                      </Paper>
                      {/* Duration of Procedure Section */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                      <Typography variant="h6" gutterBottom>
                          Visting Place
                        </Typography>
                      <FormControl fullWidth>
                            <InputLabel id="places-to-visit-label">
                              Places to Visit
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="places-to-visit-label"
                              label="Places to Visit"
                              required
                              fullWidth
                              value={form.Visiting_Places}
                              name="Visiting_Places"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Clinic">Clinic</MenuItem>
                              <MenuItem value="Hospital">
                                Hospital
                              </MenuItem>
                              <MenuItem value="Centre">Centre</MenuItem>
                              <MenuItem value="Home Visit">
                                Home Visit
                              </MenuItem>
                              <MenuItem value="Tele-Consultation">
                                Tele-Consultation
                              </MenuItem>
                            </Select>
                          </FormControl>
                        <Typography variant="h6" gutterBottom>
                          Duration of Procedure
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                          sx={{ mt: 2 }}
                        >
                        <FormControl fullWidth>
                        <TextField
                        label="Duration of Procedure"
                        type="number"
                        required
                        fullWidth
                        value={form.duration_of_procedure.time}
                        autoComplete="off"
                        name="duration_of_procedure.time"
                        onChange={forProfileChange}
                        inputProps={{ min: 1 }}
                        disabled={form.consultation_duration}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                    <InputLabel id="duration-unit-label">
                      Duration of Procedure (unit)
                    </InputLabel>
                    <Select
                    labelId="duration-unit-label"
                    label="Duration of Procedure (unit)"
                    required
                    fullWidth
                    value={form.duration_of_procedure.unit}
                    name="duration_of_procedure.unit"
                    onChange={forProfileChange}
                    disabled={form.consultation_duration}
                    >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                  <MenuItem value="weeks">Weeks</MenuItem>
                  <MenuItem value="months">Months</MenuItem>
                  <MenuItem value="years">Years</MenuItem>
                </Select>
            </FormControl>
          </Stack>
                        <FormControlLabel
                        control={
                     <Checkbox
                       name="consultation_duration"
                      checked={form.consultation_duration}
                      onChange={(e) =>
                        forProfileChange({
                          target: {
                            name: "consultation_duration",
                            value: e.target.checked,
                          },
                        })
                      }
                    disabled={
                      (!form.duration_of_procedure.time==0 )
                    }
                    />
                      }
                    label="Consultation Only"
                  />
                      </Paper>
                      <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Estimated Cost Range
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          {/* Success Rate Dropdown */}
                          <FormControl fullWidth>
                            <InputLabel id="estimated-cost-label">
                              Estimated Cost Range
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="estimated-cost-label"
                              label="Estimated Cost Range"
                              required
                              fullWidth
                              value={form.estimated_cost_range}
                              name="estimated_cost_range"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Rupee">Rupee</MenuItem>
                              <MenuItem value="Dollar">
                                Dollar
                              </MenuItem>
                              <MenuItem value="Dinar">Dinar</MenuItem>
                              <MenuItem value="Dirham">
                                Dirham
                              </MenuItem>
                              <MenuItem value="Pounds">
                                Pounds
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              label="Minimum Price"
                              type="number"
                              required
                              fullWidth
                              value={form.minprice}
                              autoComplete="off"
                              name="minprice"
                              onChange={forProfileChange}
                            />
                          </FormControl>

                          <FormControl fullWidth>
                            <TextField
                              label="Maximum Price"
                              type="number"
                              required
                              fullWidth
                              value={form.maxprice}
                              autoComplete="off"
                              name="maxprice"
                              onChange={forProfileChange}
                            />
                          </FormControl>
                        </Stack>
                      </Paper>

                      {/* Hospital Stay Section */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Hospital Stay
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          {/* <FormControl fullWidth>
                            <InputLabel id="hospital-stay-duration-label">
                              Hospital Stay
                            </InputLabel>
                            <Select
                              labelId="hospital-stay-duration-label"
                              label="Hospital Stay "
                              required
                              fullWidth
                              value={form.hospital_stay.hospital_Name || ""} // Default value if undefined
                              name="hospital_stay.hospital_Name"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Outpatient (same-day discharge)">
                                Outpatient (same-day discharge)
                              </MenuItem>
                              <MenuItem value="1 day">1 day</MenuItem>
                              <MenuItem value="2 days">2 days</MenuItem>
                              <MenuItem value="3 days">3 days</MenuItem>
                              <MenuItem value="4-7 days">4-7 days</MenuItem>
                              <MenuItem value="1-2 weeks (for major surgeries)">
                                1-2 weeks (for major surgeries)
                              </MenuItem>
                              <MenuItem value="ICU stay (if required)">
                                ICU stay (if required)
                              </MenuItem>
                              <MenuItem value="Rehabilitation facility (if needed for recovery)">
                                Rehabilitation facility (if needed for recovery)
                              </MenuItem>
                              <MenuItem value="Extended stay for complications">
                                Extended stay for complications
                              </MenuItem>
                              <MenuItem value="Home care after early discharge">
                                Home care after early discharge
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                          <FormControl fullWidth>
                            <TextField
                              label="Hospital Stay Duration"
                              type="number"
                              required
                              fullWidth
                              value={form.hospital_stay.time}
                              autoComplete="off"
                              name="hospital_stay.time"
                              onChange={forProfileChange}
                              disabled={form.consultation_stay}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="hospital-stay-unit-label">
                              Hospital Stay Duration (unit)
                            </InputLabel>
                            <Select
                              labelId="hospital-stay-unit-label"
                              label="Hospital Stay Duration (unit)"
                              required
                              fullWidth
                              value={form.hospital_stay.unit}
                              name="hospital_stay.unit"
                              onChange={forProfileChange}
                              disabled={form.consultation_stay}
                            >
                              <MenuItem value="hours">Hours</MenuItem>
                              <MenuItem value="days">Days</MenuItem>
                              <MenuItem value="weeks">Weeks</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                        <FormControlLabel
                        control={
                     <Checkbox
                       name="consultation_stay"
                      checked={form.consultation_stay}
                      onChange={(e) =>
                        forProfileChange({
                          target: {
                            name: "consultation_stay",
                            value: e.target.checked,
                          },
                        })
                      }
                    disabled={
                      (!form.hospital_stay.time==0 )
                    }
                    />
                      }
                    label="Consultation Only"
                  />
                      </Paper>

                      {/* Recovery Milestones Section */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Recovery Milestones
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="recovery-milestones-label">
                              Recovery Milestone
                            </InputLabel>
                            <Select
                              labelId="recovery-milestones-label"
                              label="Recovery Milestone"
                              required
                              fullWidth
                              value={
                                form.recovery_milestones.recovery_milestone ||
                                ""
                              } // Default value if undefined
                              name="recovery_milestones.recovery_milestone"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Long Term">
                                Long Term
                              </MenuItem>
                              <MenuItem value="Short Term">
                                Short Term
                              </MenuItem>
                              <MenuItem value="Management Only">
                                Management Only
                              </MenuItem>
                              {/* <MenuItem value="Walking without assistance in 2 weeks">
                                Walking without assistance in 2 weeks
                              </MenuItem>
                              <MenuItem value="Return to work in 6 weeks">
                                Return to work in 6 weeks
                              </MenuItem>
                              <MenuItem value="Full range of motion in 3-6 months">
                                Full range of motion in 3-6 months
                              </MenuItem>
                              <MenuItem value="Pain-free movement by 1 month">
                                Pain-free movement by 1 month
                              </MenuItem>
                              <MenuItem value="Driving within 4-6 weeks">
                                Driving within 4-6 weeks
                              </MenuItem>
                              <MenuItem value="Return to sports in 6-12 months">
                                Return to sports in 6-12 months
                              </MenuItem>
                              <MenuItem value="Normal daily activities in 3-4 weeks">
                                Normal daily activities in 3-4 weeks
                              </MenuItem>
                              <MenuItem value="Removal of surgical dressings within 10-14 days">
                                Removal of surgical dressings within 10-14 days
                              </MenuItem>
                              <MenuItem value="Discharge from physical therapy after 3-6 months">
                                Discharge from physical therapy after 3-6 months
                              </MenuItem>
                              <MenuItem value="Long-term follow-up for implant integrity (if applicable)">
                                Long-term follow-up for implant integrity (if
                                applicable)
                              </MenuItem> */}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              label="Minimum Days to Recover"
                              type="number"
                              required
                              fullWidth
                              value={form.recovery_milestones.min_days}
                              autoComplete="off"
                              name="recovery_milestones.min_days"
                              onChange={forProfileChange}
                            />
                          </FormControl>

                          <FormControl fullWidth>
                            <TextField
                              label="Maximum Days to Recover"
                              type="number"
                              required
                              fullWidth
                              value={form.recovery_milestones.max_days}
                              autoComplete="off"
                              name="recovery_milestones.max_days"
                              onChange={forProfileChange}
                            />
                          </FormControl>
                        </Stack>
                      </Paper>
                      
                      {/* <Paper elevation={3} className="p-3">
                        <Box mx={2} my={2}>
                          <FormControl fullWidth>
                            <InputLabel id="anesthesia-type-label">
                              Anesthesia Type
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="anesthesia-type-label"
                              label="Anesthesia Type"
                              required
                              fullWidth
                              value={form.Anesthesia_Type}
                              name="Anesthesia_Type"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="General anesthesia">
                                General anesthesia
                              </MenuItem>
                              <MenuItem value="Spinal anesthesia">
                                Spinal anesthesia
                              </MenuItem>
                              <MenuItem value="Local anesthesia">
                                Local anesthesia
                              </MenuItem>
                              <MenuItem value="Regional anesthesia">
                                Regional anesthesia
                              </MenuItem>
                              <MenuItem value="Conscious sedation">
                                Conscious sedation
                              </MenuItem>
                              <MenuItem value="Epidural anesthesia">
                                Epidural anesthesia
                              </MenuItem>
                              <MenuItem value="Nerve block anesthesia">
                                Nerve block anesthesia
                              </MenuItem>
                              <MenuItem value="Intravenous (IV) sedation">
                                Intravenous (IV) sedation
                              </MenuItem>
                              <MenuItem value="Topical anesthesia">
                                Topical anesthesia (e.g., for eye surgeries)
                              </MenuItem>
                              <MenuItem value="No anesthesia">
                                No anesthesia (for some diagnostic procedures)
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="recovery-time-label">
                              Average Recovery Time
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="recovery-time-label"
                              label="Average Recovery Time"
                              required
                              fullWidth
                              value={form.Recovery_Time}
                              name="Recovery_Time"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="1-2 days">1-2 days</MenuItem>
                              <MenuItem value="1-2 weeks">1-2 weeks</MenuItem>
                              <MenuItem value="4-6 weeks">4-6 weeks</MenuItem>
                              <MenuItem value="2-3 months">2-3 months</MenuItem>
                              <MenuItem value="3-6 months">3-6 months</MenuItem>
                              <MenuItem value="6-12 months (for major procedures)">
                                6-12 months (for major procedures)
                              </MenuItem>
                              <MenuItem value="Same day (for minor outpatient procedures)">
                                Same day (for minor outpatient procedures)
                              </MenuItem>
                              <MenuItem value="3-5 days (for minimally invasive surgeries)">
                                3-5 days (for minimally invasive surgeries)
                              </MenuItem>
                              <MenuItem value="Ongoing (e.g., chronic conditions management)">
                                Ongoing (e.g., chronic conditions management)
                              </MenuItem>
                              <MenuItem value="Varies depending on individual factors (e.g., age, health)">
                                Varies depending on individual factors (e.g.,
                                age, health)
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl fullWidth>
                            <InputLabel id="risks-and-complications-label">
                              Risks and Complications
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="risks-and-complications-label"
                              label="Risks and Complications"
                              required
                              fullWidth
                              value={form.Risks_and_Complications}
                              name="Risks_and_Complications"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Infection">Infection</MenuItem>
                              <MenuItem value="Blood clots">
                                Blood clots
                              </MenuItem>
                              <MenuItem value="Nerve damage">
                                Nerve damage
                              </MenuItem>
                              <MenuItem value="Implant failure">
                                Implant failure
                              </MenuItem>
                              <MenuItem value="Bleeding">Bleeding</MenuItem>
                              <MenuItem value="Anesthesia complications">
                                Anesthesia complications
                              </MenuItem>
                              <MenuItem value="Scarring">Scarring</MenuItem>
                              <MenuItem value="Chronic pain">
                                Chronic pain
                              </MenuItem>
                              <MenuItem value="Allergic reactions">
                                Allergic reactions
                              </MenuItem>
                              <MenuItem value="Organ damage or dysfunction">
                                Organ damage or dysfunction
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="pre-procedure-preparation-label">
                              Pre-Procedure Preparation
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="pre-procedure-preparation-label"
                              label="Pre-Procedure Preparation"
                              required
                              fullWidth
                              value={form.pre_procedure_preparation}
                              name="pre_procedure_preparation"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Fasting">Fasting</MenuItem>
                              <MenuItem value="Blood tests">
                                Blood tests
                              </MenuItem>
                              <MenuItem value="Imaging (e.g., X-rays, MRI)">
                                Imaging (e.g., X-rays, MRI)
                              </MenuItem>
                              <MenuItem value="Stop certain medications">
                                Stop certain medications
                              </MenuItem>
                              <MenuItem value="Physical examination">
                                Physical examination
                              </MenuItem>
                              <MenuItem value="Pre-operative consultation">
                                Pre-operative consultation
                              </MenuItem>
                              <MenuItem value="Consent forms signing">
                                Consent forms signing
                              </MenuItem>
                              <MenuItem value="Pre-surgery medication">
                                Pre-surgery medication
                              </MenuItem>
                              <MenuItem value="Arrange post-surgery care (e.g., transportation, home care)">
                                Arrange post-surgery care (e.g., transportation,
                                home care)
                              </MenuItem>
                              <MenuItem value="Lifestyle adjustments (e.g., stop smoking, dietary changes)">
                                Lifestyle adjustments (e.g., stop smoking,
                                dietary changes)
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl fullWidth>
                            <InputLabel id="post-procedure-care-label">
                              Post-Procedure Care
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="post-procedure-care-label"
                              label="Post-Procedure Care"
                              required
                              fullWidth
                              value={form.post_procedure_care}
                              name="post_procedure_care"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Physical therapy">
                                Physical therapy
                              </MenuItem>
                              <MenuItem value="Pain management (e.g., medication)">
                                Pain management (e.g., medication)
                              </MenuItem>
                              <MenuItem value="Wound care">Wound care</MenuItem>
                              <MenuItem value="Regular follow-up visits">
                                Regular follow-up visits
                              </MenuItem>
                              <MenuItem value="Diet modifications">
                                Diet modifications
                              </MenuItem>
                              <MenuItem value="Mobility aids (e.g., crutches, walker)">
                                Mobility aids (e.g., crutches, walker)
                              </MenuItem>
                              <MenuItem value="Rest and limited activity">
                                Rest and limited activity
                              </MenuItem>
                              <MenuItem value="Medication for infection prevention">
                                Medication for infection prevention
                              </MenuItem>
                              <MenuItem value="Compression garments (e.g., for varicose vein treatments)">
                                Compression garments (e.g., for varicose vein
                                treatments)
                              </MenuItem>
                              <MenuItem value="Emotional and psychological support">
                                Emotional and psychological support
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Paper> */}
                      {/* <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Estimated Cost Range
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >

                          <FormControl fullWidth>
                            <TextField
                              label="Estimated Cost Range"
                              type="number"
                              required
                              fullWidth
                              value={form.estimated_cost_range}
                              autoComplete="off"
                              name="estimated_cost_range"
                              onChange={forProfileChange}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              label="Minimum Price"
                              type="number"
                              required
                              fullWidth
                              value={form.minprice}
                              autoComplete="off"
                              name="minprice"
                              onChange={forProfileChange}
                            />
                          </FormControl>

                          <FormControl fullWidth>
                            <TextField
                              label="Maximum Price"
                              type="number"
                              required
                              fullWidth
                              value={form.maxprice}
                              autoComplete="off"
                              name="maxprice"
                              onChange={forProfileChange}
                            />
                          </FormControl>
                        </Stack>
                      </Paper> */}
                      {/* <Paper elevation={3} className="p-3">
                        <Box mx={2} my={2}>
                          <FormControl fullWidth>
                            <InputLabel id="eligibility-label">
                              Eligibility
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="eligibility-label"
                              label="Eligibility"
                              required
                              fullWidth
                              value={form.eligibility}
                              name="eligibility"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Patients with chronic pain">
                                Patients with chronic pain
                              </MenuItem>
                              <MenuItem value="Individuals with limited mobility">
                                Individuals with limited mobility
                              </MenuItem>
                              <MenuItem value="Candidates for cosmetic improvements">
                                Candidates for cosmetic improvements
                              </MenuItem>
                              <MenuItem value="Patients with heart conditions">
                                Patients with heart conditions
                              </MenuItem>
                              <MenuItem value="Those with severe arthritis">
                                Those with severe arthritis
                              </MenuItem>
                              <MenuItem value="Individuals with damaged organs">
                                Individuals with damaged organs
                              </MenuItem>
                              <MenuItem value="Patients with tumors">
                                Patients with tumors
                              </MenuItem>
                              <MenuItem value="People experiencing significant vision impairment">
                                People experiencing significant vision
                                impairment
                              </MenuItem>
                              <MenuItem value="Healthy candidates for organ transplants">
                                Healthy candidates for organ transplants
                              </MenuItem>
                              <MenuItem value="Patients whose conditions are unresponsive to other treatments">
                                Patients whose conditions are unresponsive to
                                other treatments
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Paper>
                      <Paper elevation={3} className="p-3">
                        <Box mx={2} my={2}>
                          <FormControl fullWidth>
                            <InputLabel id="alternative-treatments-label">
                              Alternative Treatments
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="alternative-treatments-label"
                              label="Alternative Treatments"
                              required
                              fullWidth
                              value={form.alternative_treatments}
                              name="alternative_treatments"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Medication">Medication</MenuItem>
                              <MenuItem value="Physical therapy">
                                Physical therapy
                              </MenuItem>
                              <MenuItem value="Injections (e.g., corticosteroids)">
                                Injections (e.g., corticosteroids)
                              </MenuItem>
                              <MenuItem value="Lifestyle modifications (e.g., weight loss, diet changes)">
                                Lifestyle modifications (e.g., weight loss, diet
                                changes)
                              </MenuItem>
                              <MenuItem value="Orthotics or braces">
                                Orthotics or braces
                              </MenuItem>
                              <MenuItem value="Non-surgical procedures (e.g., laser treatments)">
                                Non-surgical procedures (e.g., laser treatments)
                              </MenuItem>
                              <MenuItem value="Counseling or psychological support">
                                Counseling or psychological support
                              </MenuItem>
                              <MenuItem value="Acupuncture">
                                Acupuncture
                              </MenuItem>
                              <MenuItem value="Radiofrequency ablation">
                                Radiofrequency ablation
                              </MenuItem>
                              <MenuItem value="Watchful waiting or observation">
                                Watchful waiting or observation
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Paper> */}
                      
                      <Paper elevation={3} className="p-3">
                        <Box mx={2} my={2}>
                        <Typography variant="h6" gutterBottom>
                          Technology Used
                        </Typography>
                        <FormControl fullWidth>
                          <Autocomplete
                          className="mb-3 "
                          multiple
                          freeSolo
                          options={techOptions}
                          value={form.technology_used}
                          onChange={handleNewTechOption}
                          renderInput={(params) => (
                          <TextField {...params} label="Technology Used" placeholder="Type or select" />
                            )}
                         renderTags={(val, getTagProps) =>
                          val.map((option, index) => (
                            <Chip
                           key={option}
                           label={option}
                           {...getTagProps({ index })}
                           color="primary"
                         />
                        ))
                      }
                     getOptionLabel={(option) => option}
                     renderOption={(props, option, { selected }) => (
                      <li {...props}>
                      <Checkbox
                      checked={selected}
                      />
                      <ListItemText primary={option} />
                      </li>
                      )}
                     />
                      </FormControl>
                          {/* <FormControl fullWidth>
                            <InputLabel id="technology-used-label">
                              Technology Used
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="technology-used-label"
                              label="Technology Used"
                              required
                              fullWidth
                              value={form.technology_used}
                              name="technology_used"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Robotic-assisted surgery">
                                Robotic-assisted surgery
                              </MenuItem>
                              <MenuItem value="Laparoscopy">
                                Laparoscopy
                              </MenuItem>
                              <MenuItem value="Laser surgery">
                                Laser surgery
                              </MenuItem>
                              <MenuItem value="Endoscopy">Endoscopy</MenuItem>
                              <MenuItem value="Imaging guidance (e.g., MRI, CT)">
                                Imaging guidance (e.g., MRI, CT)
                              </MenuItem>
                              <MenuItem value="3D printing (e.g., custom implants)">
                                3D printing (e.g., custom implants)
                              </MenuItem>
                              <MenuItem value="Virtual reality for surgical planning">
                                Virtual reality for surgical planning
                              </MenuItem>
                              <MenuItem value="Cryotherapy">
                                Cryotherapy
                              </MenuItem>
                              <MenuItem value="Radiofrequency ablation">
                                Radiofrequency ablation
                              </MenuItem>
                              <MenuItem value="Genetic testing for precision treatments">
                                Genetic testing for precision treatments
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                        </Box>
                      </Paper>

                      <Paper elevation={3} sx={{ p: 3 }}>
                      
                        
                          
                          {/* <FormControl fullWidth>
                            <InputLabel id="estimated-cost-label">
                              Estimated Cost Range
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="estimated-cost-label"
                              label="Estimated Cost Range"
                              required
                              fullWidth
                              value={form.estimated_cost_range}
                              name="estimated_cost_range"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Rupee">Rupee</MenuItem>
                              <MenuItem value="Dollar">
                                Dollar
                              </MenuItem>
                              <MenuItem value="Dinar">Dinar</MenuItem>
                              <MenuItem value="Dirham">
                                Dirham
                              </MenuItem>
                              <MenuItem value="Pounds">
                                Pounds
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              label="Minimum Price"
                              type="number"
                              required
                              fullWidth
                              value={form.minprice}
                              autoComplete="off"
                              name="minprice"
                              onChange={forProfileChange}
                            />
                          </FormControl> */}

                          {/* <FormControl fullWidth>
                            <TextField
                              label="Maximum Price"
                              type="number"
                              required
                              fullWidth
                              value={form.maxprice}
                              autoComplete="off"
                              name="maxprice"
                              onChange={forProfileChange}
                            />
                          </FormControl> */}
                        
                        <Typography variant="h6" gutterBottom>
                          Success Rate
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                          className="mb-4"
                        >
                          
                          <FormControl fullWidth>
                            
                            <TextField
                              label="Minimum"
                              type="number"
                              required={form.success_rate.who_much==""}
                              fullWidth
                              value={form.success_rate.Minimum_days}
                              autoComplete="off"
                              name="success_rate.Minimum_days"
                              onChange={forProfileChange}
                              inputProps={{
                                min: 0,
                                max: 100,
                              }}
                              // disabled={form.success_rate.who_much!=""}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              label="Maximum"
                              type="number"
                              required={form.success_rate.who_much==""}
                              fullWidth
                              value={form.success_rate.max_days}
                              autoComplete="off"
                              name="success_rate.max_days"
                              onChange={forProfileChange}
                              inputProps={{
                                min: 0,
                                max: 100,
                              }}
                              
                            />
                          </FormControl>
                          
                        
                          {/* <FormControl fullWidth>
                            <InputLabel id="success-rate-label">
                              Success Rate (%)
                            </InputLabel>
                            <Select
                              labelId="success-rate-label"
                              label="Success Rate (%)"
                              required
                              fullWidth
                              value={form.success_rate["who_much"] || ""}
                              name="success_rate.who_much"
                              onChange={forProfileChange}
                            >
                               <MenuItem value="80-85">80% - 85%</MenuItem>
                              <MenuItem value="85-90">85% - 90%</MenuItem>
                              <MenuItem value="90-95">90% - 95%</MenuItem>
                              <MenuItem value="95-99">95% - 99%</MenuItem> 
                              <MenuItem value="varies">
                                Varies by patient’s condition and procedure
                              </MenuItem>
                              <MenuItem value="high_success">
                                High success rate with experienced surgeons
                              </MenuItem>
                              <MenuItem value="post_surgical">
                                Success influenced by post-surgical care
                                adherence
                              </MenuItem>
                              <MenuItem value="long_term">
                                Long-term success rates (e.g., 10-year outcomes)
                              </MenuItem>
                              <MenuItem value="minimally_invasive">
                                Higher success with minimally invasive
                                techniques
                              </MenuItem>
                              <MenuItem value="coexisting_conditions">
                                Success may depend on coexisting medical
                                conditions
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                        </Stack>
                        {/* Success Rate Dropdown */}
                        <FormControl fullWidth>
                          <Autocomplete
                          className="mb-3 "
                          multiple
                          freeSolo
                          options={successOptions}
                          value={form.success_rate.who_much}
                          onChange={handleNewSuccessUpOption}
                          renderInput={(params) => (
                          <TextField {...params} label="Success Rate Options" placeholder="Type or select" />
                            )}
                         renderTags={(val, getTagProps) =>
                          val.map((option, index) => (
                            <Chip
                           key={option}
                           label={option}
                           {...getTagProps({ index })}
                           color="primary"
                         />
                        ))
                      }
                     getOptionLabel={(option) => option}
                     renderOption={(props, option, { selected }) => (
                    <li {...props}>
                    <Checkbox
                    checked={selected}
                    />
                    <ListItemText primary={option} />
                    </li>
                    )}
                    />
                      </FormControl>
                      </Paper>
                      <Paper elevation={3} className="p-3">
                        <Box mx={2} my={2}>
                        <Typography variant="h6" gutterBottom>
                          Follow Up Care
                        </Typography>
                        <FormControl fullWidth>
                          <Autocomplete
                          className="mb-3 "
                          multiple
                          freeSolo
                          options={followUpOptions}
                          value={form.follow_up_care}
                          onChange={handleNewFollowUpOption}
                          renderInput={(params) => (
                          <TextField {...params} label="Follow Up Care" placeholder="Type or select" />
                            )}
                         renderTags={(val, getTagProps) =>
                          val.map((option, index) => (
                            <Chip
                           key={option}
                           label={option}
                           {...getTagProps({ index })}
                           color="primary"
                         />
                        ))
                      }
                     getOptionLabel={(option) => option}
                     renderOption={(props, option, { selected }) => (
                    <li {...props}>
                    <Checkbox
                    checked={selected}
                    />
                    <ListItemText primary={option} />
                    </li>
                    )}
                    />
                      </FormControl>
                          {/* <FormControl fullWidth>
                            <InputLabel id="follow-up-care-label">
                              Follow Up Care
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="follow-up-care-label"
                              label="Follow Up Care"
                              required
                              fullWidth
                              value={form.follow_up_care || ""} // Default value if undefined
                              name="follow_up_care"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Physical therapy sessions">
                                Physical therapy sessions
                              </MenuItem>
                              <MenuItem value="Home exercises">
                                Home exercises
                              </MenuItem>
                              <MenuItem value="Regular check-ups">
                                Regular check-ups
                              </MenuItem>
                              <MenuItem value="Imaging tests (e.g., X-rays, MRI)">
                                Imaging tests (e.g., X-rays, MRI)
                              </MenuItem>
                              <MenuItem value="Wound monitoring">
                                Wound monitoring
                              </MenuItem>
                              <MenuItem value="Removal of sutures or staples">
                                Removal of sutures or staples
                              </MenuItem>
                              <MenuItem value="Adjustments in medications">
                                Adjustments in medications
                              </MenuItem>
                              <MenuItem value="Occupational therapy (if needed)">
                                Occupational therapy (if needed)
                              </MenuItem>
                              <MenuItem value="Cardiac rehabilitation (for heart procedures)">
                                Cardiac rehabilitation (for heart procedures)
                              </MenuItem>
                              <MenuItem value="Psychosocial support">
                                Psychosocial support
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                          {/* <FormControl fullWidth>
                            <InputLabel id="possible-side-effects-label">
                              Possible Side Effects
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="possible-side-effects-label"
                              label="Possible Side Effects"
                              required
                              fullWidth
                              value={form.possible_side_effects}
                              name="possible_side_effects"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Temporary swelling">
                                Temporary swelling
                              </MenuItem>
                              <MenuItem value="Bruising">Bruising</MenuItem>
                              <MenuItem value="Nausea">Nausea</MenuItem>
                              <MenuItem value="Pain at the surgical site">
                                Pain at the surgical site
                              </MenuItem>
                              <MenuItem value="Fatigue">Fatigue</MenuItem>
                              <MenuItem value="Dizziness">Dizziness</MenuItem>
                              <MenuItem value="Scarring">Scarring</MenuItem>
                              <MenuItem value="Allergic reactions to medications">
                                Allergic reactions to medications
                              </MenuItem>
                              <MenuItem value="Muscle weakness">
                                Muscle weakness
                              </MenuItem>
                              <MenuItem value="Blood pressure fluctuations">
                                Blood pressure fluctuations
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="contraindications-label">
                              Contraindications
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="contraindications-label"
                              label="Contraindications"
                              required
                              fullWidth
                              value={form.contraindications}
                              name="contraindications"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Severe heart conditions">
                                Severe heart conditions
                              </MenuItem>
                              <MenuItem value="Uncontrolled diabetes">
                                Uncontrolled diabetes
                              </MenuItem>
                              <MenuItem value="Active infections">
                                Active infections
                              </MenuItem>
                              <MenuItem value="Bleeding disorders">
                                Bleeding disorders
                              </MenuItem>
                              <MenuItem value="Pregnancy">Pregnancy</MenuItem>
                              <MenuItem value="Allergies to anesthesia">
                                Allergies to anesthesia
                              </MenuItem>
                              <MenuItem value="Severe obesity (in some procedures)">
                                Severe obesity (in some procedures)
                              </MenuItem>
                              <MenuItem value="Poor kidney or liver function">
                                Poor kidney or liver function
                              </MenuItem>
                              <MenuItem value="Immune system disorders">
                                Immune system disorders
                              </MenuItem>
                              <MenuItem value="Chronic respiratory conditions">
                                Chronic respiratory conditions
                              </MenuItem>
                            </Select>
                          </FormControl> */} 
                        </Box>
                      </Paper>
                      

                      <Paper elevation={3} className="p-3">
                        <Box mx={2} my={2}>
                        <Typography variant="h6" gutterBottom>
                          Insurance Coverage
                        </Typography>
                        <FormControl component="fieldset">
                        <RadioGroup
                          row
                          value={form.insurance_coverage}
                          name="insurance_coverage"
                          onChange={(e) => {
                          const selectedValue = e.target.value === 'true'; // Convert string to boolean
                          setShowInsuranceOptions(selectedValue);
                          forProfileChange(e);
                          // console.log(e.target.value);
                          }}
                        >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                        </FormControl>
                          {showInsuranceOptions && (
                            <Autocomplete
                            className="mb-3 "
                            multiple
                            
                            options={insuranceList}
                            value={form.insurance_type}
                            onChange={handleNewInsuranceOption}
                            renderInput={(params) => (
                            <TextField {...params} label="Insurance Type" placeholder="Select" />
                              )}
                           renderTags={(val, getTagProps) =>
                            val.map((option, index) => (
                              <Chip
                             key={option}
                             label={option}
                             {...getTagProps({ index })}
                             color="primary"
                           />
                          ))
                        }
                       getOptionLabel={(option) => option}
                       disableCloseOnSelect
                       onInputChange={(event, value) => {
                        // If the input value is not in the options, clear it
                        if (!insuranceList.includes(value)) {
                          event.preventDefault();
                        }
                      }}
                       renderOption={(props, option, { selected }) => (
                        <li {...props}>
                        <Checkbox
                        checked={selected}
                        />
                        <ListItemText primary={option} />
                        </li>
                        )}
                       />
                        // </FormControl>
                          //   <FormControl fullWidth>
                          //   <InputLabel id="insurance-coverage-label">
                          //     Insurance Coverage
                          //   </InputLabel>
                          //   <Select
                          //     className="mb-3"
                          //     labelId="insurance-coverage-label"
                          //     label="Insurance Coverage"
                          //     fullWidth
                          //     value={form.insurance_type}
                          //     name="insurance_type"
                          //     onChange={forProfileChange}
                          //   >
                          //     <MenuItem value="Covered by most health insurance plans">
                          //       Covered by most health insurance plans
                          //     </MenuItem>
                          //     <MenuItem value="Prior authorization required">
                          //       Prior authorization required
                          //     </MenuItem>
                          //     <MenuItem value="Out-of-pocket costs for co-pays">
                          //       Out-of-pocket costs for co-pays
                          //     </MenuItem>
                          //     <MenuItem value="Government health coverage (e.g., Medicare, Medicaid)">
                          //       Government health coverage (e.g., Medicare,
                          //       Medicaid)
                          //     </MenuItem>
                          //     <MenuItem value="High-deductible health plans (HDHP) considerations">
                          //       High-deductible health plans (HDHP)
                          //       considerations
                          //     </MenuItem>
                          //     <MenuItem value="No coverage for cosmetic procedures">
                          //       No coverage for cosmetic procedures
                          //     </MenuItem>
                          //     <MenuItem value="Partial coverage based on procedure type">
                          //       Partial coverage based on procedure type
                          //     </MenuItem>
                          //     <MenuItem value="International medical tourism packages">
                          //       International medical tourism packages
                          //     </MenuItem>
                          //     <MenuItem value="Employer-sponsored health plans">
                          //       Employer-sponsored health plans
                          //     </MenuItem>
                          //     <MenuItem value="Supplemental insurance options">
                          //       Supplemental insurance options
                          //     </MenuItem>
                          //   </Select>
                          // </FormControl>
                          )}
                        </Box>
                      </Paper>

                      {/* Success Rate Section */}
                      {/* <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Success Rate
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          {[
                            {
                              label: "Success Rate (%)",
                              name: "success_rate.who_much",
                            },
                            {
                              label: "Minimum Days",
                              name: "success_rate.Minimum_days",
                            },
                            {
                              label: "Maximum Days",
                              name: "success_rate.max_days",
                            },
                          ].map((field) => (
                            <FormControl fullWidth key={field.name}>
                              <TextField
                                label={field.label}
                                type="number"
                                fullWidth
                                value={
                                  form.success_rate[field.name.split(".")[1]]
                                }
                                autoComplete="off"
                                name={field.name}
                                onChange={forProfileChange}
                              />
                            </FormControl>
                          ))}
                        </Stack>
                      </Paper> */}

                      {/* Submit and Close Buttons */}
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{ mt: 4 }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {setShoweditPopup(false);setSelectedProcedureOptions([]);setShowInsuranceOptions(false); }}
                        >
                          Close
                        </Button>
                      </Stack>
                    </Stack>
                  </Container>
                </div>
              </div>
            </form>
          )}
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default TreatmentsPage;
