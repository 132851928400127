import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Grid,
  TextField,
  ThemeProvider,
  createTheme,
  OutlinedInput,
  Chip,
  MenuItem,
  Select,
  FormControl,
  Typography,
  Container,
  RadioGroup,
  Radio,
  Switch,
  Stack,
  Checkbox,
} from "@mui/material";
import { UploadButton } from "../../CustomStyles/Styles";
import {
  aws_url,
  doctorDetailsGet,
  doctorDetailsUpdate,
  createSpecialization,
  getAllLanguages,
  deleteLanguage,
  languageUpdate,
} from "../../Service/Services";
import { toast } from "react-hot-toast";
import ImageUpload from "./ImageUpload";
import EditIcon from "@mui/icons-material/Edit";
import { getAllSpecializations } from "../../Service/Services";
import { SpecializationUpdate } from "../../Service/Services";
//import Profile from "../../assets/user.png";
import CarouselComponent from "./App";
import styled from "styled-components";
const DEFAULT_IMAGE =
  "https://images.healthmudraa.com/1718098598539-blank-profile-picture-973460_1280.png";

const DocProfile = () => {
  const ResponsiveDiv = styled.div`
    @media (max-width: 471px) {
      width: 30%;
    }

    /* For medium devices (tablets, 600px to 900px) */
    @media (min-width: 601px) and (max-width: 900px) {
      width: 50%;
    }

    /* For large devices (desktops, 900px and up) */
    @media (min-width: 901px) {
      width: 90%;
    }
  `;

  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {},
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [lang,setLang] = useState([]);
  // const lang = [
  //   "English",
  //   "Hindi",
  //   "Telugu",
  //   "Tamil",
  //   "Malayali",
  //   "Kannada",
  //   "Odiya",
  //   "Bengali",
  // ];

  const [preLang,setPreLang] = useState([]);
  const temp = [
    "Endocrinology",
    "Cardiology",
    "Dermatology",
    "Orthopedics",
    "Neurology",
    "Gynecology",
    "Pediatrics",
    "Psychiatry",
    "Radiology",
    "Haematology",
    "Bariatric",
    "Physical Therapy",
    "Gastroenterology",
    "Nephrology",
    "Hematology",
    "Oncology",
    "Pulmanology",
    "Opthalmology",
    "Pulmonology",
    "Urology",
    "Infectious Disease",
    "Allergy and Immunology",
    "Rheumatology",
    "Anesthesiology",
    "Plastic Surgery",
    "Ophthalmology",
    "Otolaryngology (ENT)",
    "Maxillofacial Surgery",
    "Vascular Surgery",
    "Neurosurgery",
    "Reproductive Medicine",
    "Neonatology",
    "Geriatrics",
    "Palliative Care",
    "Sports Medicine",
    "Pain Management",
    "Clinical Genetics",
    "Medical Genetics",
    "Pediatric Surgery",
    "Pediatric Cardiology",
    "Pediatric Endocrinology",
    "Pediatric Hematology-Oncology",
    "Pediatric Nephrology",
    "Pediatric Neurology",
    "Pediatric Rheumatology",
    "Pediatric Gastroenterology",
    "Pediatric Pulmonology",
    "Pediatric Infectious Disease",
    "Pediatric Allergy and Immunology",
    "Pediatric Critical Care Medicine",
    "Pediatric Emergency Medicine",
    "Pediatric Dentistry",
    "Pediatric Orthopedics",
    "Pediatric Rehabilitation Medicine",
    "Pediatric Radiology",
    "Pediatric Dermatology",
    "Pediatric Neurosurgery",
    "Pediatric Urology",
    "Pediatric Otolaryngology",
    "Pediatric Plastic Surgery",
    "Pediatric Sports Medicine",
    "Pediatric Hospice and Palliative Medicine",
    "Wilderness Medicine",
    "Aerospace Medicine",
    "Legal Medicine (Forensic Medicine)",
    "Addiction Medicine",
    "Pain Medicine",
    "Clinical Pharmacology",
    "Geriatric Psychiatry",
    "Forensic Psychiatry",
    "Sleep Medicine",
    " Reproductive Medicine (Fertility)",
    "Andrology",
    "Complex General Surgical Oncology",
    "Female Pelvic Medicine and Reconstructive Surgery (Urogynecology)",
    "Hospice and Palliative Medicine",
    "Preventive Medicine",
    "Disaster Medicine",
    "Medical Toxicology",
    "Clinical Informatics",
    "Neuropsychology",
    "Pediatric Neuropsychology",
    "Child and Adolescent Psychiatry",
    "Developmental-Behavioral Pediatrics",
    "Behavioral Neurology",
    "Vascular Neurology",
    "Psychopharmacology",
    "Molecular Pathology",
    "Clinical Oncology",
    "Orthodontics",
    "Gerontology",
    "Public Health",
    "Medical Laboratory Science",
    "Medical Illustration",
    "Health Informatics",
    "Telemedicine",
    "Occupational Therapy",
    "Speech-Language Pathology",
    "Nutrigenetics",
    "Orthoptics",
    "Cardiothoracic Surgery",
    "Rehabilitation Counseling",
    "Behavioral Medicine",
    "Medical Physics",
  ];

  const [customSpecialization,setCustomSpecialization] = useState(false);

  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    area:"",
    city:"",
    state:"",
    country:"",
    specilization: [],
    gender: "",
    languages: [],
    seotitle: "",
    seodescription: "",
    homevisit: false,
    onlinevisit: false,
    profilepicurl: "",
    heropicurl: "",
    qualifications: [],
    experiences: [],
    registration: [],
    govtId: [],
    awards: [],
    about: "",
    hospitalvisits: true,
    features: [],
  });
  const navigate = useNavigate();

  const [specalizationFlag, setSpecializationFlag] = useState();
  const [preSpecs, setPreSpecs] = useState([]);
  const [specializationData, setSpecializationData] = useState([]);

  const getSpecialization = async () => {
    try {
      const response = await getAllSpecializations();
      console.log(response?.data.data);
      setSpecializationData(response?.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getLanguages = async()=>{
    try {
      const response = await getAllLanguages();
      console.log(response?.data.data);
      setLang(response?.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const forProfileChange = (e) => {
    const { name, value, type, checked } = e.target;
    if(name === "specilization"){
      if(value === "new_specialization"){
        setForm({...form,specilization:""})
        setCustomSpecialization(true);
        return
      }
      const newValue = [value];
      console.log(name,value);
      setForm((pre) => ({ ...pre, [name]: newValue }));
    }else{
    console.log(form);
    console.log(name,value);
    const newValue = type === "checkbox" ? checked : value;
    setForm((pre) => ({ ...pre, [name]: newValue }));
    }
  };

  const forDoctorGet = async () => {
    const response = await doctorDetailsGet();
    if (response?.data.status) {
      const doctorData = response.data?.data;

      // Set default value if not present
      if (doctorData.hospitalvisits === undefined) {
        doctorData.hospitalvisits = true;
      }

      setForm(doctorData);
      setPreSpecs(doctorData.specilization);
      setPreLang(doctorData.languages);
      console.log(doctorData.specilization);
      
      console.log(response.data.data);
    } else {
      toast.error(response?.data.message);
    }

    if (!response.data.data.verified) navigate("/doctor/alert");
  };

  useEffect(() => {
    forDoctorGet();
    getSpecialization();
    getLanguages()
  }, []); // Ensure this effect runs only once

  const forDoctorSubmit = async () => {
    try {
      console.log(form);
      if(customSpecialization){
        setForm({...form,specilization:[]})
      }
      try {
        for(let i=0;i<form.specilization.length;i++){
          const temp = preSpecs.filter((s) => form.specilization[i]===s)
          if(temp.length===0){
            const specObj = specializationData.filter((s) => s.specialization===form.specilization[i])
            console.log(specObj[0]);
            console.log(specializationData);
            
            const data = {...specObj[0],doctors:[...specObj[0].doctors,form.firstname]}
            console.log(data);
            
            const res = await SpecializationUpdate(data);
          }
        }
        for(let i=0;i<form.languages.length;i++){
          const temp = preLang.filter((s) => form.languages[i]===s)
          if(temp.length===0){
            const langObj = lang.filter((s) => s.language===form.languages[i])
            console.log(langObj[0]);
            console.log(lang);
            
            const data = {...langObj[0],doctors:[...langObj[0].doctors,form.firstname]};
            console.log(data);
            
            const res = await languageUpdate(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
      try {
        for(let i=0;i<preSpecs.length;i++){
          const temp = form.specilization.filter((s) => s===preSpecs[i])
          console.log(preSpecs);
          console.log(form.specilization);
          if(temp.length===0){
            const specObj = specializationData.filter((s) => s.specialization===preSpecs[i])
            console.log(specObj[0]);
            console.log(specializationData);
            const removeDoctor = specObj[0].doctors.filter((s) => s!==form.firstname)
            console.log(removeDoctor);
            const data = {...specObj[0],doctors:removeDoctor}
            const res = await SpecializationUpdate(data);
          }
        } 
         for(let i=0;i<preLang.length;i++){
          const temp = form.languages.filter((s) => s===preLang[i]);
          console.log(preLang);
          console.log(form.languages);
          if(temp.length===0){
            const langObj = lang.filter((s) => s.language===preLang[i])
            console.log(langObj[0]);
            console.log(lang);
            const removeDoctor = langObj[0].doctors.filter((s) => s!==form.firstname)
            const data = {...langObj[0],doctors:removeDoctor}
            const res = await languageUpdate(data);
          }
        }  
      } catch (error) {
        console.log(error);       
      }
      const responseJson = await doctorDetailsUpdate(form); 
      if (responseJson.data.status) {
        toast.success(responseJson.data.message);
      } else {
        toast.error(responseJson.data.message);
      }
    } catch (error) {
      toast.error("Error occured!");
    }
    forDoctorGet();
    getSpecialization();
  };

  const addSpecialization = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const value = form.specilization[0]
    for(let i=0;i<specializationData.length;i++){
      if(value.toLowerCase() === specializationData[i].specialization.toLowerCase()){
        setForm({...form,specilization:[specializationData[i].specialization]})
        toast.success("Specialization already exist");
        setCustomSpecialization(false);
        return
      }
    }
    try {
        let response;
          console.log(form);
          const data = {
            specialization:form.specilization[0],
            doctor:[],
          }
          response = await createSpecialization(data); // Pass form object directly
          
        if (response && response.status === 200) {  
          toast.success("Request send to admin successfully");
          getSpecialization();
        } else {
          toast.error("Failed to send request to admin.");
        }
      } catch (error) {
        toast.error(
          error.response ? error.response.data.message : "An error occurred."
        );
        console.error("Error while submitting:", error);
      }
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <ResponsiveDiv>
          <CarouselComponent isProfile={true} />
        </ResponsiveDiv>

        <Box display="flex">
          {/* <p className="">Hero Background </p>
          <ImageUpload
            setForm={setForm}
            fieldname="heropicurl"
            imageurl={
              form.heropicurl ? `${aws_url}/${form.heropicurl}` : DEFAULT_IMAGE
            }
          /> */}
          <p>Profile Picture</p>
          <ImageUpload
            setForm={setForm}
            fieldname="profilepicurl"
            imageurl={
              form.profilepicurl
                ? `${aws_url}/${form.profilepicurl}`
                : DEFAULT_IMAGE
            }
          />
        </Box>

        <Container>
          <Box component="form" noValidate sx={{ mt: 3, mb: 2 }}>
            <Grid container spacing={2} key={form._id}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  value={form.firstname}
                  autoComplete="off"
                  name="firstname"
                  type="string"
                  id="FName"
                  onChange={(e) => forProfileChange(e)}
                  placeholder="First Name*"
                  InputProps={{
                    sx: {
                      height: "2.4em",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  value={form.lastname}
                  autoComplete="off"
                  name="lastname"
                  type="string"
                  id="LName"
                  onChange={forProfileChange}
                  placeholder="Last Name*"
                  InputProps={{
                    sx: {
                      height: "2.4em",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  required
                  fullWidth
                  value={form.email}
                  autoComplete="off"
                  name="email"
                  type="email"
                  id="email"
                  onChange={forProfileChange}
                  placeholder="Email*"
                  InputProps={{
                    sx: {
                      height: "2.4em",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  value={form.mobile}
                  autoComplete="off"
                  name="mobile"
                  type="number"
                  id="mob"
                  onChange={forProfileChange}
                  placeholder="Mobile Number*"
                  InputProps={{
                    sx: {
                      height: "2.4em",
                    },
                  }}
                />
              </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      value={form.area}
                      autoComplete="off"
                      name="area"
                      type="string"
                      id="area"
                      onChange={forProfileChange}
                      placeholder="Area*"
                      InputProps={{
                        sx: { height: "2.4em" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      value={form.city}
                      autoComplete="off"
                      name="city"
                      type="string"
                      id="city"
                      onChange={forProfileChange}
                      placeholder="City*"
                      InputProps={{
                        sx: { height: "2.4em" },
                      }}
                    />
                  </Grid>

                  {/* Second Row: State & Country */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      value={form.state}
                      autoComplete="off"
                      name="state"
                      type="string"
                      id="state"
                      onChange={forProfileChange}
                      placeholder="State*"
                      InputProps={{
                        sx: { height: "2.4em" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      value={form.country}
                      autoComplete="off"
                      name="country"
                      type="string"
                      id="country"
                      onChange={forProfileChange}
                      placeholder="Country*"
                      InputProps={{
                        sx: { height: "2.4em" },
                      }}
                    />
                  </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth
                >
                  {customSpecialization?
                  <div className="flex gap-4">
                    <TextField
                  required
                  fullWidth
                  value={form.specilization}
                  autoComplete="off"
                  name="specilization"
                  type="string"
                  id="specilization"
                  onChange={forProfileChange}
                  placeholder="New Specialization"
                  InputProps={{
                    sx: {
                      height: "2.4em",
                    },
                  }}
                />
                <button className="border bg-green-500 text-white rounded p-2"
                onClick={addSpecialization}>Request</button>
                  </div>
                  :<Select
                    name="specilization"
                    value={form.specilization ? form.specilization : []}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    sx={{
                      height: `${
                        form?.specilization?.length >= 3 ? "auto" : "2.4em"
                      }`,
                    }}
                    // multiple
                    displayEmpty
                    onChange={(e) => {
                      setSpecializationFlag(true);
                      forProfileChange(e);
                    }}
                    input={<OutlinedInput id="select-multiple-chip" sx={{}} />}
                  //   renderValue={(selected) => (
                  //     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  //       {selected && selected.length === 0 ? (
                  //         <Typography
                  //           component={"p"}
                  //           sx={{ color: "#9E9FA8", fontFamily: "Montserrat" }}
                  //         >
                  //           Specilization
                  //         </Typography>
                  //       ) : (
                  //         selected &&
                  //         selected.map((value) => (
                  //           <Chip
                  //             sx={{ fontFamily: "Montserrat" }}
                  //             key={value}
                  //             label={value}
                  //           />
                  //         ))
                  //       )}
                  //     </Box>
                  //   )}
                  //   MenuProps={MenuProps}
                  //   inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="new_specialization">
                      <Typography component={"p"} sx={{ fontWeight: "bold" }}>
                       <EditIcon /> Custom Specilization
                      </Typography>
                    </MenuItem>
                    {specializationData.map((name) => (
                      <MenuItem
                        sx={{ fontFamily: "Montserrat" }}
                        key={name._id}
                        value={name.specialization}
                      >
                        {name.status === "verified" ? name.specialization : `${name.specialization}(not verified)`}
                      </MenuItem>
                    ))}
                  </Select>}
                </FormControl>
              </Grid>

              
              <Grid item xs={12} sm={6}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  value={form.gender}
                  name="gender"
                  sx={{
                    border: "1px solid #9E9FA8",
                    padding: 0.28,
                    borderRadius: 1,
                  }}
                  onChange={forProfileChange}
                >
                  <FormControlLabel
                    className="ps-2"
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    className="ps-2"
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    className="ps-2"
                    value="Others"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Select
                    name="languages"
                    value={form.languages ? form.languages : []}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    sx={{
                      height: `${
                        form.languages?.length >= 3 ? "auto" : "2.4em"
                      }`,
                    }}
                    multiple
                    displayEmpty
                    onChange={forProfileChange}
                    input={<OutlinedInput id="select-multiple-chip" sx={{}} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected && selected.length === 0 ? (
                          <Typography
                            component={"p"}
                            sx={{ color: "#9E9FA8", fontFamily: "Montserrat" }}
                          >
                            Languages
                          </Typography>
                        ) : (
                          selected &&
                          selected.map((value) => (
                            <Chip
                              sx={{ fontFamily: "Montserrat" }}
                              key={value}
                              label={value}
                            />
                          ))
                        )}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <Typography component={"p"} sx={{ fontWeight: "bold" }}>
                        Languages Known
                      </Typography>
                    </MenuItem>
                    {lang.map((name) => (
                      <MenuItem
                        sx={{ fontFamily: "Montserrat" }}
                        key={name._id}
                        value={name.language}
                      >
                        {name.language}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  value={form.seotitle}
                  autoComplete="off"
                  name="seotitle"
                  type="string"
                  id="seotitle"
                  onChange={forProfileChange}
                  placeholder="Seo Title"
                  InputProps={{
                    sx: {
                      height: "2.4em",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  value={form.seodescription}
                  autoComplete="off"
                  name="seodescription"
                  type="string"
                  id="seodescription"
                  onChange={forProfileChange}
                  placeholder="Seo Description"
                  InputProps={{
                    sx: {
                      height: "2.4em",
                    },
                  }}
                />
              </Grid> */}

              {/* <Stack direction="row" spacing={1} alignItems="center"></Stack> */}
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: "column", sm: "row" }} // Stack items in a column on smaller screens and in a row on larger screens
                  spacing={1}
                  alignItems="center"
                  sx={{
                    border: "1px solid #9E9FA8",
                    padding: 0.28,
                    borderRadius: 1,
                    justifyContent: "space-between",
                    width: "100%", // Use 100% to make the Stack responsive
                  }}
                >
                  <div
                    style={{
                      width: "100%", // Full width for each div
                      maxWidth: "300px", // Set a max-width to control the maximum size of each div
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography className="ms-2">
                      Clinic/hospital visits
                    </Typography>
                    <Checkbox
                      name="hospitalvisits"
                      checked={form.hospitalvisits}
                      onChange={forProfileChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography>
                      {form.hospitalvisits ? "Yes" : "No"}
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography className="ms-2">Home Visit</Typography>
                    <Checkbox
                      name="homevisit"
                      checked={form.homevisit}
                      onChange={forProfileChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography>{form.homevisit ? "Yes" : "No"}</Typography>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography className="ms-2">Online Visit</Typography>
                    <Checkbox
                      name="onlinevisit"
                      checked={form.onlinevisit}
                      onChange={forProfileChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography>{form.onlinevisit ? "Yes" : "No"}</Typography>
                  </div>
                </Stack>
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    border: "1px solid #9E9FA8",
                    padding: 0.28,
                    borderRadius: 1,
                  }}
                >
                  
                </Stack>
              </Grid> */}
            </Grid>
            {/* <DocDetails details={form} setDetails={setForm} /> */}
            <div
              className=" mt-3"
              style={{
                paddingTop: "50px",
                paddingBottom: "20px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <UploadButton
                onClick={forDoctorSubmit}
                style={{ fontFamily: "Montserrat" }}
              >
                Save Changes
              </UploadButton>
            </div>
          </Box>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default DocProfile;
