import React, { useState } from "react";
import {
  Box,
  Container,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import { UploadButton } from "../../CustomStyles/Styles";
import { doctorUploadPassword } from "../../Service/Services";
import toast from "react-hot-toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordReset = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get("token");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
    },
    typography: {
      fontFamily: "Montserrat",
    },
  });

  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility

  const handleSubmit = async () => {
    if (!token) {
      toast.error("Invalid token. Please try again.");
      return;
    }

    if (password !== cpassword) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }

    const data = { token, password };
    setLoading(true); // Start loading when submitting

    try {
      const response = await doctorUploadPassword(data);

      if (response?.data?.status) {
        toast.success(response.data.message);
        navigate("/doctor/login");
      } else {
        toast.error(response?.data?.message || "Password reset failed.");
      }
    } catch (error) {
      toast.error("An error occurred while resetting your password.");
    } finally {
      setLoading(false); // Reset loading state after submission
    }

    setPassword("");
    setCpassword("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box
          sx={{
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            sx={{
              mt: 1,
              borderRadius: 2,
              p: 4,
              boxShadow: "0px 10px 40px 0px rgba(0,0,0,0.1)",
              backgroundColor: "#ffffff",
              maxWidth: 400,
              width: "100%",
            }}
          >
            <Box display="flex" justifyContent="center" mb={3}>
              <Box component="img" src={Logo} alt="Logo" sx={{ width: 150 }} />
            </Box>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: 700,
                mb: 2,
                color: "primary.main",
              }}
            >
              Update Password
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", color: "text.secondary", mb: 3 }}
            >
              Please enter and confirm your new password below.
            </Typography>
            <TextField
              margin="normal"
              value={password}
              required
              fullWidth
              id="password"
              autoComplete="off"
              type={showPassword ? "text" : "password"} // Show or hide password
              placeholder="New Password"
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                style: { fontFamily: "Montserrat" },
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    position="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="normal"
              value={cpassword}
              required
              fullWidth
              id="cpassword"
              autoComplete="off"
              type={showPassword ? "text" : "password"} // Show or hide password
              placeholder="Confirm Password"
              onChange={(e) => setCpassword(e.target.value)}
              InputProps={{
                style: { fontFamily: "Montserrat" },
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    position="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{ mb: 3 }}
            />
            <UploadButton
              onClick={handleSubmit}
              fullWidth
              type="button"
              sx={{
                width: "100%",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                mt: 2,
                backgroundColor: "primary.main",
                textAlign: "Center",
                color: "#ffffff",
                ":hover": {
                  backgroundColor: "#0b275d",
                },
              }}
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "Reset Password"
              )}
            </UploadButton>

            {/* Back Button */}
            <Box mt={2} display="flex" justifyContent="center">
              <button
                onClick={() => navigate("/doctor/login")}
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "#133680",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Back to Login
              </button>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PasswordReset;
