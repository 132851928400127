import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Stack,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme,
  TextField,
  FormControl,
  MenuItem,
  Select,
  Button,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import Plyr from "plyr-react";
import { UploadButton } from "../../CustomStyles/Styles";
import {
  doctorDetailsGet,
  getDoctorVideos,
  createDoctorVideo,
  deleteDoctorVideo,
  updateDoctorVideo,
} from "../../Service/Services";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import { url } from "../../Service/Services";
const API = "AIzaSyBzMOtXpvplDBZ0j8nmsQdkbVrE1aPHRBs";
//  const doctorId = '6691445df662fec383fb71d3'; // Demo valid doctor ID

// Function to fetch videos from YouTube API

const fetchVideos = async (channelId, setChannelVideos, setError) => {
  try {
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/search?key=${API}&channelId=${channelId}&part=snippet,id&order=date&maxResults=20`
    );
    const data = await response.json();
    if (response.ok) {
      console.log("Fetched Videos: ", data.items); // Debugging
      setChannelVideos(data.items);
      setError("");
    } else {
      console.error("Error fetching videos:", data.error.message); // Debugging
      setError(data.error.message);
      setChannelVideos([]);
    }
  } catch (error) {
    console.error("Failed to fetch videos:", error); // Debugging
    setError("Failed to fetch videos");
    setChannelVideos([]);
  }
};

const addVideosToDatabase = async (
  doctorid,
  channelId,
  channelVideos,
  setChannelId,
  setChannelVideos,
  setError
) => {
  try {
    // Prepare video data
    const videosData = channelVideos.map((video) => ({
      link: `https://www.youtube.com/watch?v=${video.id.videoId}`,
      title: video.snippet.title,
      description: video.snippet.description || "No description available", // Provide a default description if missing
      category: "Default Category",
    }));

    console.log("Prepared videos data: ", videosData); // Debugging

    // Make POST request to the backend
    const response = await fetch(
      `https://healthmudraa-backend.vercel.app/video/add-videos`,
      //`${url}/video/add-videos`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ doctorid, videos: videosData, channelId }),
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("Backend Response: ", result); // Debugging
      setChannelId(""); // Clear the input
      setChannelVideos([]); // Clear the videos display
      alert("Videos added to database successfully!");
    } else {
      const data = await response.json();
      console.error("Failed to add videos:", data.message || "Unknown error"); // Debugging
      setError(data.message || "Failed to add videos");
    }
  } catch (error) {
    console.error("Error adding videos to database:", error); // Debugging
    setError("Failed to add videos to database.");
  }
};

const VideoFetch = () => {

  const [videos, setVideos] = useState([]);
  const [hasPermission, setHasPermission] = useState(null); // Track permission status

  // useEffect(() => {
  //   const fetchVideos = async () => {
  //     const response = await getDoctorVideos();
  //     if (response.data.status) {
  //       setVideos(response.data.data);
  //     }
  //   };
  //   fetchVideos();
  // }, []);

  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        const response = await doctorDetailsGet(); // Fetch doctor details
        //console.log("API Response:", response); // Log full response
        //console.log("Doctor Data:", response?.data?.data); // Log doctor object
  
        if (response?.data?.status) {
          const permissions = response?.data?.data?.permissions || {}; // Ensure permissions exist
          //console.log("Doctor Permissions:", permissions); // Debugging log
  
          setHasPermission(permissions.videos); // Check if `videos` permission is `true`
        } else {
          console.log("Permissions not found in response");
          setHasPermission(false); // Default to false if data is missing
        }
      } catch (error) {
        console.error("Error fetching doctor details:", error);
        setHasPermission(false);
      }
    };
  
    fetchDoctorDetails();
  }, []);
  

  const divRef = useRef(null);
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {},
  });

  const [view, setView] = useState("single"); // 'single' or 'channel'
  const [videoInfo, setVideoInfo] = useState({
    link: "",
    title: "",
    description: "",
    category: "",
    keywords: [],
    questionsAnswers: [],
  });

  const [categories] = useState([
    "General",
    "Brain and Nerves",
    "Bones and Muscles",
    "Skin/hair care",
    "Emergency care",
    "Pregnancy and Childbirth",
    "Eye care",
    "Child care",
    "Mental Health",
    "Lungs care",
    "Urinary System care",
    "Cancer Care",
    "Teeth and Oral care",
    "Veterinary - Animals care",
    "Diet/Nutrition",
    "Stomach-Intestines",
    "Liver care",
    "Kidney care",
    "Ear Nose Throat",
    "Acupressure therapy",
  ]);

  const [currentKeyword, setCurrentKeyword] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [currentQuestion2, setCurrentQuestion2] = useState("");
  const [currentAnswer2, setCurrentAnswer2] = useState("");

  const [source, setSource] = useState(null);
  const [video, setVideo] = useState([]);
  const [edit, setEdit] = useState(false);

  const handlePost = async () => {
    if (videoInfo.title && videoInfo.link) {
      const responseJson = await createDoctorVideo(videoInfo);
      if (responseJson.data.status) {
        toast.success(responseJson.data.message);
        getDoctorDetails();
        setVideoInfo({
          link: "",
          title: "",
          description: "",
          category: "",
          keywords: [],
          questionsAnswers: [],
        });
      } else {
        toast.error(responseJson.data.message);
      }
    }
  };

  const deleteVideo = async (id) => {
    const responseJson = await deleteDoctorVideo(id);
    if (responseJson.data.status) {
      toast.success(responseJson.data.message);
      getDoctorDetails();
    } else {
      toast.error(responseJson.data.message);
    }
  };

  const updateVideo = async () => {
    const responseJson = await updateDoctorVideo(videoInfo._id, videoInfo);
    if (responseJson.data.status) {
      toast.success(responseJson.data.message);
      setEdit(false);
      setVideoInfo({
        link: "",
        title: "",
        description: "",
        category: "",
        keywords: [],
        questionsAnswers: [],
      });
      getDoctorDetails();
    } else {
      toast.error(responseJson.data.message);
    }
  };

  const handleAddKeyword = () => {
    if (currentKeyword.trim() !== "") {
      setVideoInfo({
        ...videoInfo,
        keywords: [...videoInfo.keywords, currentKeyword],
      });
      setCurrentKeyword("");
    }
  };

  const handleDeleteKeyword = (index) => {
    const newKeywords = [...videoInfo.keywords];
    newKeywords.splice(index, 1);
    setVideoInfo({
      ...videoInfo,
      keywords: newKeywords,
    });
  };

  const handleAddQuestionAnswer = () => {
    // Temporarily store questions and answers to avoid clearing issues
    let newQuestionsAnswers = [];

    // Check and add first question-answer pair
    if (currentQuestion.trim() !== "" && currentAnswer.trim() !== "") {
      newQuestionsAnswers.push({
        question: currentQuestion,
        answer: currentAnswer,
      });
      setCurrentQuestion("");
      setCurrentAnswer("");
    }

    // Check and add second question-answer pair
    if (currentQuestion2.trim() !== "" && currentAnswer2.trim() !== "") {
      newQuestionsAnswers.push({
        question: currentQuestion2,
        answer: currentAnswer2,
      });
      setCurrentQuestion2("");
      setCurrentAnswer2("");
    }

    // Add all valid question-answer pairs to videoInfo
    if (newQuestionsAnswers.length > 0) {
      setVideoInfo((prevVideoInfo) => ({
        ...prevVideoInfo,
        questionsAnswers: [
          ...prevVideoInfo.questionsAnswers,
          ...newQuestionsAnswers,
        ],
      }));
    }
  };

  const handleDeleteQuestionAnswer = (index) => {
    setVideoInfo((prevVideoInfo) => ({
      ...prevVideoInfo,
      questionsAnswers: prevVideoInfo.questionsAnswers.filter(
        (_, i) => i !== index
      ),
    }));
  };

  // const handleUpdate = async (index) => {
  //   if (edit) {
  //     console.log("CHECK:NO MORE EDITS>>...");
  //     return;
  //   }
  //   setEdit(true);
  //   const details = video[index];
  //   setVideoInfo(details);
  //   const updatedVideoList = video.filter((_, xd) => xd !== index);
  //   setVideo(updatedVideoList);
  // };

  const handleUpdate = async (index) => {
    if (edit) {
      console.log("CHECK:NO MORE EDITS>>...");
      return;
    }
    setEdit(true);
    const details = video[index];
    setVideoInfo(details);

    // Scroll to the desired div
    divRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

    const updatedVideoList = video.filter((_, xd) => xd !== index);
    setVideo(updatedVideoList);
  };
  const forSubmit = () => {
    if (videoInfo.link) {
      setSource(videoInfo.link);
    }
  };

  const [doctorid, setDoctorid] = useState("");
  const [lastAdded, setLastAdded] = useState(null);
  const [initialChannelId, setinitialChannelId] = useState("");
  const [channelId, setChannelId] = useState("");
  const [channelVideos, setChannelVideos] = useState([]);
  const [error, setError] = useState("");
  const [showContent, setShowContent] = useState(true);
  const navigate = useNavigate();

  const getDoctorDetails = async () => {
    const responseJson = await doctorDetailsGet();
    if (!responseJson.data.data.verified) navigate("/doctor/alert");

    if (responseJson.data.status) {
      console.log("details in videofetch about doctor", responseJson.data);
      const doctorid = responseJson.data.data._id;
      setDoctorid(doctorid);
      const channelid = responseJson.data.data.channelId;
      setinitialChannelId(channelid);
      const lastAdded = responseJson.data.data.lastUpdatedDate;
      setLastAdded(lastAdded);

      // const videos = await getDoctorVideos(doctorid);
      // if (videos.data.status) {
      //   setVideo(videos.data.data);
      // }
        //useEffect(() => {
    //const fetchVideos = async () => {
      const response = await getDoctorVideos(doctorid);
      if (response.data.status) {
        setVideos(response.data.data);
      }
    //};
    //fetchVideos();
  //}, []);
    }
  };

  useEffect(() => {
    getDoctorDetails();
  }, []);

  const handleChannelSubmit = (e) => {
    e.preventDefault();
    fetchVideos(channelId, setChannelVideos, setError);
    setShowContent(false); // Hide content after submission
  };

  const handleChannelDelete = (videoId) => {
    // console.log(Delete video with ID: ${videoId});
    setChannelVideos(
      channelVideos.filter((video) => video.id.videoId !== videoId)
    );
  };

  const LazyVideo = ({ video }) => {
    const { ref, inView } = useInView({
      triggerOnce: true, // Load once when in view
      threshold: 0.5, // Load when 50% of the video is visible
    });
  
    return (
      // <React.Fragment>
      <Grid
        item
        ref={ref}
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={3}
      >
        <Card className="m-2">
          <Box component="div">
            {inView ? (
              <Plyr
                source={{
                  type: "video",
                  sources: [{ src: video.link, provider: "youtube" }],
                }}
                options={{
                  controls: [],
                  autoplay: false,
                  clickToPlay: true,
                }}
              />
            ) : (
              <Typography align="center">Loading...</Typography>
            )}
  
            <CardContent>
              <Typography component="p">{video.title}</Typography>
            </CardContent>
            <CardActions>
              <IconButton onClick={() => handleUpdate(video._id)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => deleteVideo(video._id)}>
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Box>
        </Card>
      </Grid>
      //</React.Fragment>
    );
  };

  //console.log("hasPermission",hasPermission)
  if (hasPermission === null) {
    return (
      <React.Fragment>
        <Typography>Loading...</Typography>
      </React.Fragment>
    );
  }

  if (!hasPermission) {
    return (
      <React.Fragment>
        <Typography variant="h5" color="error" align="center" sx={{ mt: 5 }}>
          You don't have permission for this page.
        </Typography>
        </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <style>
        {`

  .accordion-button:not(.collapsed) {
    color: blue;
    background-color: white;
    box-shadow: 1px solid green;
  }
  .accordion {
    --bs-accordion-btn-focus-box-shadow: none;
  }
  .faq-title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .faq-container {
    width: 80%;
    border-radius: 10px;
  }
  .my-7 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }
  .my-8 {
    color: var(--On-surface, #262626);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
  }
  .my-9 {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .my-10 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .Recommended {
    background: #37FBFB;
    border-radius: 20px;
    width: 170px;
    height: 30px;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 700;
    padding-top: 2px;
    margin-left: auto;
    gap: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    // opacity: 0;
  }
  .Link-box {
    display: flex;
    width: 397px;
    align-items: center;
    gap: 6px;
  }
  .box-text {
    color: var(--On-surface, #262626);
    font-family: "arial";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .input-width {
    width: 397px;
    height: 36px;
    padding: 5px;
    gap: 0px;
    opacity: 0px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }
  .btn-width {
    width: 129px;
    height: 44px;
    padding: 16px 24px;
    gap: 10px;
    border-radius: 10px;
    opacity: 0px;
    border: 1px solid #133682;
  }
  .btn-width2 {
    width: 159px;
    height: 44px;
    padding: 16px 24px;
    gap: 10px;
    border-radius: 10px;
    opacity: 0px;
    border: 1px solid #133682;
  }
  .Questions {
    width: 181px;
    height: 44px;
    padding: 16px 4px;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #133682;
    opacity: 0px;
    margin-left: auto;
  }
  .margin-bottom {
    margin-bottom: 20px;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-left: auto;
  }

  /* Responsive Styles */
  @media (max-width: 1024px) {
    .faq-container {
      width: 90%;
    }
    .Recommended, .Questions {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    .box-text, .my-8 {
      font-size: 18px;
      text-align: center;
    }
    .Link-box, .input-width, .btn-width, .btn-width2 {
      width: 100%;
    }
    .center {
      flex-direction: column;
      margin-left: 0;
    }
    .Recommended {
      width: 150px;
      height: 25px;
    }
  }

  @media (max-width: 480px) {
 
    .box-text, .my-8 {
      font-size: 16px;
      line-height: 20px;
    }
    .Recommended, .Questions {
      
      position: relative;
      left: 200px;
     
    }
    .input-width {
      height: 30px;
    }
    .btn-width, .btn-width2 {
      width: 100%;
      height: 40px;
    }
      .youtubeFont{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
         .youtubeFont2{
        font-size: 12px;
        // line-height: 20px;
        text-align: center;
      }
  }
  `}
      </style>

      {/* <div>
        {doctorid && <h2>{doctorid}</h2>}
         {initialChannelId && <h2>{initialChannelId}</h2>}
        {lastAdded && <h2>{lastAdded}</h2>}
      </div>  */}
      <ThemeProvider theme={theme}>
        <div style={{ padding: "20px" }} className="padding-responsive">
          <div>
            {/* Display message if channelId is already linked */}
            {initialChannelId ? (
              <>
                {/* 77320jay@gmail.com */}
                {/* Test@123 */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className="my-4 youtubeFont2 "
                    variant="h3"
                    component="h3"
                    style={{
                      color: "",
                      fontWeight: "bold",
                      fontSize: "30px",
                    }}
                  >
                    Your YouTube Channel is{" "}
                    <span style={{ color: "#1FC557" }}>Connected</span>
                  </Typography>
                </div>
                <div className="my-4">
                  <div className="st1">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      From Now,
                    </p>
                    <ul>
                      <li>Health Mudraa will manage the account here...</li>
                      <li className="pt-2 list-disc ml-5">
                        From Now, our AI bot will will import the videos
                        uploaded on this YouTube channel to Health Mudraa
                        Automatically.
                      </li>
                      <li className="pt-2 list-disc ml-5">
                        We can get Video Title, Description, keywords from
                        whatever you enter in YouTube and fetch here...{" "}
                      </li>
                      <li className="pt-2 list-disc ml-5">
                        You can Edit, add details, Delete the Health Mudraa
                        videos now
                      </li>
                    </ul>
                  </div>

                  <div className="st3">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      Tips:-
                    </p>
                    <ul>
                      <li className=" list-disc ml-5 ">
                        We suggest you to edit each video Title, description,
                        keywords, category in Health Mudraa for better SEO
                        ranking Google will read this Health Mudraa video as new
                        video in Internet. it will will be double benefit for
                        You!
                      </li>
                      <li className=" list-disc ml-5 ">
                        Most Important tip is Add Q&A (Type video topic on
                        Google {">"} Go to section “People also ask” {">"} Take
                        relevant Questions and Answer it from Your words for
                        better reach in Google)
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ justifyContent: "center" }}>
                {showContent && (
                  <>
                    <Typography className="Recommended">Recommended</Typography>
                  </>
                )}

                <Typography
                  variant="h5"
                  component="h5"
                  className="position-relative youtubeFont"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Connect Your Youtube Channel
                  {/* <span class="badge text-bg-success mx-2 mb-2" style={{position:'absolute',top:'-10px'}}>Recommended</span> */}
                </Typography>

                <Typography
                  variant="h5"
                  component="h5"
                  className="position-relative  Link-box"
                >
                  <p className="box-text">Add Link Channel Link in box</p>
                </Typography>

                <form
                  onSubmit={handleChannelSubmit}
                  style={{
                    display: "flex",
                    gap: "20px",

                    alignItems: "center",
                  }}
                >
                  {showContent && (
                    <>
                      <Typography className="youtubeFont">
                        https://www.youtube.com/channel/
                      </Typography>
                      <input
                        type="text"
                        className="input-width"
                        value={channelId}
                        onChange={(e) => setChannelId(e.target.value)}
                        required
                      />
                    </>
                  )}
                  {!showContent && (
                    <>
                      <div className="center">
                        <Typography>
                          https://www.youtube.com/channel/{channelId}
                        </Typography>
                        {channelVideos.length > 0 && doctorid && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              addVideosToDatabase(
                                doctorid,
                                channelId,
                                channelVideos,
                                setChannelId,
                                setChannelVideos,
                                setError
                              ).then(() => {
                                // Reload the page after the videos are added to the database
                                window.location.reload();
                              });
                            }}
                            style={{}}
                          >
                            Add videos to Health Mudraa
                          </Button>
                        )}
                      </div>
                    </>
                  )}

                  {/* {showContent && (
                 
                )} */}
                  {showContent && (
                    <Button
                      type="submit"
                      color="primary"
                      className=" btn-width"
                    >
                      connect
                    </Button>
                  )}
                </form>
                {showContent && (
                  <div className="my-4">
                    <div className="st1">
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          letterSpacing: "1px",
                        }}
                      >
                        How to :
                      </p>
                      <ul>
                        <li className="list-disc">
                          Go to your YouTube Account &gt; click "YouTube Studio"
                          &gt; now go to the top &gt; channel link &gt; copy
                          only the right side half of the link and paste here.
                          Eg - UComtHWcgd1L6tg6jxxxxxxxx
                        </li>

                        <li className=" list-disc ">
                          Check the videos you want to add in Health Mudraa, you
                          can delete some if it's unnecessary, then click “Add
                          to Health Mudraa”
                        </li>
                      </ul>
                    </div>

                    <div className="st2">
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          letterSpacing: "1px",
                        }}
                      >
                        Why it's Recommended :
                      </p>
                      <ul>
                        <li className=" list-disc ">
                          Connect Your Youtube Channel Once, HealthMudraa Will
                          Manage it!
                        </li>
                        <li className=" list-disc ">
                          We can Reduce Your Work; Our Software will
                          automatically import already uploaded videos and
                          future videos too!
                        </li>
                        <li className=" list-disc ">
                          You can upload videos as usual on YouTube, and we will
                          handle it for you in HealthMudraa. We can get video
                          title, description, and keywords from what you entered
                          on YouTube.
                        </li>
                      </ul>
                    </div>

                    <div className="st3">
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          letterSpacing: "1px",
                        }}
                      >
                        Tips :
                      </p>
                      <ul>
                        <li className=" list-disc ">
                          The most important tip is to add Q&A (Type video topic
                          on Google, go to the section “People also ask”, take
                          relevant questions and answer them in your words for
                          better reach in Google)
                        </li>
                        <li className=" list-disc ">
                          We can reduce your work; our software will
                          automatically import already uploaded videos and
                          future videos too!
                        </li>
                        <li className=" list-disc ">
                          Same Video with Title - We suggest you edit each video
                          title, description, keywords, and category in Health
                          Mudraa for better SEO ranking on Google. Google will
                          read this Health Mudraa video as a new video on the
                          internet.
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {error && <Typography color="error">{error}</Typography>}
                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                  {channelVideos.map((video) => (
                    <Grid
                      item
                      key={video.id.videoId}
                      xs={12} // full-width on extra-small screens
                      sm={6} // 50% width on small screens
                      md={4} // 33% width on medium screens
                      lg={3} // 25% width on large screens
                    >
                      <Card>
                        <iframe
                          width="100%"
                          height="200"
                          src={`https://www.youtube.com/embed/${video.id.videoId}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title={video.snippet.title}
                        ></iframe>
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="h2">
                            {video.snippet.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {video.snippet.description}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <IconButton
                            onClick={() =>
                              handleChannelDelete(video.id.videoId)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </div>

          {showContent && (
            <>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h5"
                  component={"h5"}
                  className="mb-2"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                  ref={divRef}
                  id="scrollTarget"
                >
                  Upload A Single Video
                </Typography>
                <label
                  htmlFor="firstname"
                  style={{
                    paddingBottom: "5px",
                    fontSize: "18px",
                    paddingTop: "18px",
                  }}
                >
                  Paste Video URL
                </label>
                <Stack direction="row" spacing={1} alignItems="center">
                  <TextField
                    required
                    fullWidth
                    value={videoInfo.link}
                    autoComplete="off"
                    name="fetchvideo"
                    type="string"
                    placeholder="Eg. URL"
                    onChange={(e) =>
                      setVideoInfo({ ...videoInfo, link: e.target.value })
                    }
                    InputProps={{
                      sx: {
                        height: "2.2em",
                      },
                    }}
                  />
                  <UploadButton onClick={forSubmit}>Import</UploadButton>
                </Stack>

                <Box component={"div"} className="my-2">
                  {source && (
                    <Plyr
                      source={{
                        type: "video",
                        sources: [
                          {
                            src: source,
                            provider: "youtube",
                          },
                        ],
                      }}
                    />
                  )}
                  <label
                    htmlFor="firstname"
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Video Title
                  </label>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                      sx: {
                        height: "2.2em",
                      },
                    }}
                    placeholder="Eg. How to reduce Diabetes"
                    value={videoInfo.title}
                    onChange={(e) =>
                      setVideoInfo({ ...videoInfo, title: e.target.value })
                    }
                    sx={{}}
                  />

                  <label
                    htmlFor="firstname"
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Category
                  </label>
                  <FormControl fullWidth sx={{}}>
                    <Select
                      placeholder="Video Category"
                      labelId="demo-simple-select-label"
                      sx={{ height: "2.4em" }}
                      name="category"
                      value={videoInfo.category}
                      onChange={(e) =>
                        setVideoInfo({ ...videoInfo, category: e.target.value })
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Place
                      </MenuItem>
                      {categories &&
                        categories.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>

                    <Grid item xs={12} sm={12} md={12} lg={10}>
                      <label
                        style={{
                          paddingBottom: "5px",
                          fontSize: "18px",
                          paddingTop: "10px",
                        }}
                      >
                        Keywords
                      </label>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <TextField
                          required
                          fullWidth
                          value={currentKeyword}
                          autoComplete="off"
                          name="degree"
                          type="string"
                          onChange={(e) => setCurrentKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleAddKeyword();
                            }
                          }}
                          placeholder="Eg. Fever"
                          InputProps={{
                            sx: {
                              height: "2.4em",
                            },
                          }}
                        />
                      </Stack>

                      <div style={{ marginTop: "1em" }}>
                        {videoInfo.keywords.map((keyword, index) => (
                          <Chip
                            key={index}
                            label={keyword}
                            onDelete={() => handleDeleteKeyword(index)}
                            style={{ marginRight: "5px", marginBottom: "5px" }}
                          />
                        ))}
                      </div>
                    </Grid>
                  </FormControl>
                  <label
                    htmlFor="firstname"
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Description
                  </label>
                  <TextField
                    multiline
                    minRows={4}
                    fullWidth
                    autoComplete="off"
                    placeholder="Eg. "
                    value={videoInfo.description}
                    onChange={(e) =>
                      setVideoInfo((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                    sx={{}}
                  />
                </Box>

                <Grid item xs={12} sm={12} md={12} lg={10}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className="mb-2"
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Important Questions
                  </Typography>

                  <label
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Question
                  </label>
                  <TextField
                    fullWidth
                    value={currentQuestion}
                    autoComplete="off"
                    name="question"
                    onChange={(e) => setCurrentQuestion(e.target.value)}
                    InputProps={{ sx: { height: "2.4em" } }}
                  />

                  <label
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Answer
                  </label>
                  <TextField
                    fullWidth
                    value={currentAnswer}
                    autoComplete="off"
                    name="answer"
                    onChange={(e) => setCurrentAnswer(e.target.value)}
                    placeholder="E.g"
                    InputProps={{ sx: { height: "2.4em" } }}
                  />
                  {/* <label
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Question2
                  </label>
                  <TextField
                    fullWidth
                    value={currentQuestion2}
                    autoComplete="off"
                    name="question2"
                    onChange={(e) => setCurrentQuestion2(e.target.value)}
                    InputProps={{ sx: { height: "2.4em" } }}
                  />

                  <label
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Answer
                  </label>
                  <TextField
                    fullWidth
                    value={currentAnswer2}
                    autoComplete="off"
                    name="answe2"
                    onChange={(e) => setCurrentAnswer2(e.target.value)}
                    placeholder="E.g"
                    InputProps={{ sx: { height: "2.4em" } }}
                  /> */}

                  <Button
                    className="Questions"
                    onClick={handleAddQuestionAnswer}
                    style={{ marginTop: "1em" }}
                  >
                    Save Questions
                  </Button>

                  <div style={{ marginTop: "2em" }}>
                    {videoInfo.questionsAnswers.map((qa, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography style={{ marginRight: "10px" }}>
                          Q: {qa.question} - A: {qa.answer}
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDeleteQuestionAnswer(index)}
                        >
                          Delete
                        </Button>
                      </div>
                    ))}
                  </div>
                </Grid>

                <div
                  className="d-flex justify-content-center align-items-center mt-3 "
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "20px",
                   
                    marginBottom: "1em",
                    gap: "10px",
                  }}
                >
                  {/* <Button
               className="btn-width2"
                onClick={() => {
                  setVideoInfo({
                    ...videoInfo,
                    isDraft: true,
                  });
                  edit ? updateVideo() : handlePost();
                }}
              >
                Save Draft
              </Button> */}
                  <div className="justify-content-center align-items-center ">
                    <UploadButton
                      onClick={() => {
                        edit ? updateVideo() : handlePost();
                      }}
                      style={{
                        backgroundColor: "#133680",
                        color: "white",
                      }}
                    >
                      {edit ? "Upload Video" : "Upload"}
                    </UploadButton>
                  </div>
                </div>
              </ThemeProvider>
            </>
          )}
          {/* <div style={{ marginTop: "10em" }}>
      <h1>Your Videos:</h1>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "2em",
        }}
      >
        {Array.isArray(videos) && videos.length > 0 ? (
          videos.map((video) => <LazyVideo key={video._id} video={video} />)
        ) : (
          <Typography variant="h6" component="p">
            No videos available.
          </Typography>
        )}
      </Grid>
    </div> */}

          <div style={{ marginTop: "10em" }}>
            <h1>Your Videos:</h1>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "2em",
              }}
            >
              
        {/* {Array.isArray(videos) && videos.length > 0 ? (
          videos.map((video, index) => (
            <Grid
              item
              key={video._id}
              xs={12} // full-width on extra-small screens
              sm={6} // 50% width on small screens
              md={4} // 33% width on medium screens
              lg={4} // 33% width on large screens
              xl={3} // 25% width on extra-large screens
            >
              <Card className="m-2">
                <Box component="div">
                  {video.link && (
                    <Plyr
                      source={{
                        type: "video",
                        sources: [
                          {
                            src: video.link,
                            provider: "youtube",
                          },
                        ],
                      }}
                      options={{
                        controls: [], // Disable controls initially
                        autoplay: false, // Disable autoplay
                        clickToPlay: false, // Allow click to play
                      }}
                    />
                  )}

                  <CardContent>
                    <Typography component="p">{video.title}</Typography>
                  </CardContent>
                  <CardActions>
                    <IconButton onClick={() => handleUpdate(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteVideo(video._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                </Box>
              </Card> 
            </Grid>
          ))
        )  */}
        {Array.isArray(videos) && videos.length > 0 ? (
          videos.map((video) => <LazyVideo key={video._id} video={video} />)
        ) : (
                <Typography variant="h6" component="p">
                  No videos available.
                </Typography>
              )}
            </Grid>
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default VideoFetch;
