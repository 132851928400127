import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  ThemeProvider,
  Typography,
  createTheme,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LogButton } from "../../CustomStyles/Styles";
import { Link, useNavigate } from "react-router-dom";
import { doctorSignup, sendOtp } from "../../Service/Services";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import DoctorSign from "../../assets/docsignin.jpg";
import DocmobileSignup from "./DocmobileSignup";
import LogoImage from "../../assets/image.png";

const DocSignup = () => {
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
  });

  const countryCodes = [
    { value: "+91", label: "+91(IND)" },
    { value: "+1", label: "+1(USA)" },
    { value: "+44", label: "+44(UK)" },
  ];

  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumber, setMobileNumber] = useState("");
  const [doctor, setDoctor] = useState({
    email: "",
    mobile: null,
    password: "",
    confirmpassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 750px)").matches
  );
  const [otp, setOTP] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerifying, setIsOtpVerifying] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [otpTimer, setOtpTimer] = useState(600);
  const [otpTimerRunning, setOtpTimerRunning] = useState(false);
  const navigate = useNavigate();
  const [isResendDisabled, setIsResendDisabled] = useState(true); // disable resend initially

  const [hasAlphabet, setHasAlphabet] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [isValidLen, setIsValidLen] = useState(false);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.matchMedia("(max-width: 750px)").matches);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let timer;
    if (otpTimerRunning) {
      timer = setInterval(() => {
        setOtpTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setOtpTimerRunning(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpTimerRunning]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const forCountryCode = (e) => {
    setCountryCode(e.target.value);
  };

  const forMobileNumber = (e) => {
    setMobileNumber(e.target.value);
  };

  const doctorChange = (e) => {
    const { name, value } = e.target;
    setDoctor({ ...doctor, [name]: value });
  };

  const checkPassword = async (e) => {
    const { name, value } = e.target;
    setDoctor({ ...doctor, [name]: value });

    const hasAlphabet = /[a-zA-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSymbol = /[^a-zA-Z0-9]/.test(value);
    const isValidLen = value.length >= 8;

    setHasAlphabet(hasAlphabet);
    setHasNumber(hasNumber);
    setHasSymbol(hasSymbol);
    setIsValidLen(isValidLen);
  };

  const forDoctorSignup = async () => {
    if (!(hasAlphabet && hasNumber && hasSymbol && isValidLen)) {
      toast.error("Password must be strong");
      return;
    }
    const { email, password, confirmpassword } = doctor;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && password && confirmpassword) {
      if (!emailRegex.test(email)) {
        toast.error("Please enter a valid email address");
        return;
      }

      setIsLoading(true);
      if (!(hasAlphabet && hasNumber && hasSymbol && isValidLen)) {
        toast.error("Password must be strong");
        return;
      }
      if (password !== confirmpassword) {
        toast.error("Password does not match");
        setIsLoading(false);
      } else {
        try {
          const response = await sendOtp({ email });
          setOTP(response.data.otp);
          setIsOtpSent(true);
          setOtpTimer(600);
          setOtpTimerRunning(true);
          setIsLoading(false);
        } catch (error) {
          toast.error("An error occurred while sending OTP");
          setIsLoading(false);
        }
      }
    } else {
      toast.error("All fields are required!");
      if (password !== confirmpassword) {
        toast.error("Password does not match");
      }
    }
  };

  const verifyOtp = async () => {
    if (otpInput) {
      setIsOtpVerifying(true);
      if (otpInput === otp) {
        try {
          const { email, password } = doctor;
          const data = {
            email,
            otp: otpInput,
            password,
            countrycode: countryCode,
            mobile: mobileNumber,
          };
          const response = await doctorSignup(data);
          if (response?.data) {
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("type", "doctors");
            navigate("/doctor/profile");
            toast.success("Account created successfully!");
          } else {
            toast.error("Failed to verify OTP");
          }
        } catch (error) {
          toast.error("An error occurred during OTP verification");
        }
      } else {
        toast.error("OTP does not match");
      }
      setIsOtpVerifying(false);
    } else {
      toast.error("Please enter the OTP");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return isMobile ? (
    <DocmobileSignup />
  ) : (
    <React.Fragment>
      <Box
        component={"div"}
        style={{
          fontFamily: "Montserrat",
          fontWeight: "bold",
          minHeight: "100vh",
          display: "flex",
          backgroundColor: "#D7E7FF",
        }}
      >
        <ThemeProvider theme={theme}>
          <Box
            component="img"
            src={DoctorSign}
            style={{
              width: "50%",
              height: "100vh",
              objectFit: "cover",
              display: "block",
            }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "2rem",
              width: "50%",
            }}
          >
            <Box
              component="img"
              src={LogoImage}
              style={{
                top: "64px",
                left: "calc(50% - 170px/2 - 84.5px)",
                width: "170px",
                height: "47px",
              }}
            />
            <Card
              sx={{
                p: 4,
                width: "100%",
                maxWidth: "480px",
                borderRadius: "16px",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)", // Soft shadow for modern look
                backgroundColor: "#FFFFFF",
              }}
            >
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  marginBottom: "24px",
                  fontFamily: "Poppins",
                  color: "#2D3748",
                }}
              >
                Create an Account
              </Typography>
              <Box component="form" sx={{ width: "100%" }}>
                {!isOtpSent ? (
                  <>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      placeholder="Enter your email"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <PersonIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={doctorChange}
                      name="email"
                    />
                    <TextField
                      label="Mobile Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      placeholder="Enter your mobile number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <select
                              value={countryCode}
                              onChange={forCountryCode}
                              style={{
                                border: "none",
                                background: "transparent",
                                outline: "none",
                                fontFamily: "Montserrat",
                                cursor: "pointer",
                              }}
                            >
                              {countryCodes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <PhoneIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={forMobileNumber}
                      name="mobileNumber"
                    />
                    <TextField
                      label="Password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={checkPassword}
                      name="password"
                    />
                    {doctor.password.length >= 1 && (
                      <Box mt={2}>
                        <Typography variant="body2" fontWeight="bold">
                          Password Requirements:
                        </Typography>
                        <ul
                          style={{
                            paddingLeft: "1.5rem",
                            margin: 0,
                            fontSize: "0.85rem",
                          }}
                        >
                          <li
                            style={{
                              color: hasAlphabet ? "#4CAF50" : "#E53935",
                            }}
                          >
                            Contains at least one alphabet
                          </li>
                          <li
                            style={{ color: hasNumber ? "#4CAF50" : "#E53935" }}
                          >
                            Contains at least one number
                          </li>
                          <li
                            style={{ color: hasSymbol ? "#4CAF50" : "#E53935" }}
                          >
                            Contains at least one special character (e.g., @, #,
                            $, etc.)
                          </li>
                          <li
                            style={{
                              color: isValidLen ? "#4CAF50" : "#E53935",
                            }}
                          >
                            At least 8 characters long
                          </li>
                        </ul>
                      </Box>
                    )}
                    <TextField
                      label="Confirm Password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type={showPassword ? "text" : "password"}
                      placeholder="Re-enter your password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={doctorChange}
                      name="confirmpassword"
                    />
                    <LogButton
                      variant="contained"
                      fullWidth
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        height: "50px",
                        borderRadius: "10px",
                        marginTop: "20px",
                        background: "linear-gradient(90deg, #3182CE, #63B3ED)",
                        color: "#FFFFFF",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        transition: "transform 0.2s ease, box-shadow 0.2s ease",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "translateY(-2px)";
                        e.currentTarget.style.boxShadow =
                          "0px 6px 12px rgba(0, 0, 0, 0.15)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "translateY(0)";
                        e.currentTarget.style.boxShadow =
                          "0px 4px 8px rgba(0, 0, 0, 0.1)";
                      }}
                      onClick={forDoctorSignup}
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress size={24} /> : "Sign Up"}
                    </LogButton>

                    <Box mt={3}>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#718096",
                          fontSize: "14px",
                          textAlign: "center",
                          lineHeight: "1.5",
                        }}
                      >
                        By signing up, you agree to our{" "}
                        <Link
                          to="/privacypolicy"
                          style={{
                            color: "#3182CE",
                            textDecoration: "none",
                            fontWeight: "600",
                          }}
                        >
                          Terms and Conditions
                        </Link>
                        .
                      </Typography>
                    </Box>

                    <Box mt={3} textAlign="center">
                      <Typography
                        variant="body2"
                        style={{
                          color: "#4A5568",
                          fontSize: "14px",
                          marginBottom: "8px",
                        }}
                      >
                        Already have an account?{" "}
                        <Link
                          to="/doctor/login"
                          style={{
                            color: "#3182CE",
                            textDecoration: "none",
                            fontWeight: "600",
                          }}
                        >
                          Sign In
                        </Link>
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#4A5568",
                          fontSize: "14px",
                        }}
                      ></Typography>
                    </Box>
                  </>
                ) : (
                  <Box textAlign="center">
                    <Typography
                      variant="body1"
                      component="p"
                      style={{
                        margin: "20px 0",
                        fontSize: "16px",
                        color: "#4A4A4A",
                      }}
                    >
                      We have sent an OTP to your email. Please enter it below
                      to verify your account.
                    </Typography>

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="otp"
                      label="OTP"
                      name="otp"
                      placeholder="Enter OTP"
                      value={otpInput}
                      onChange={(e) => setOtpInput(e.target.value)}
                      InputProps={{
                        style: {
                          height: "50px",
                          fontFamily: "Montserrat",
                          padding: "14px 16px",
                          borderRadius: "10px",
                          border: "1px solid #D1D1D1",
                          background: "#FFFFFF",
                        },
                      }}
                    />

                    <Box mt={2}>
                      {otpTimer > 0 ? (
                        <Typography
                          variant="body2"
                          style={{ color: "#4CAF50", fontWeight: "bold" }}
                        >
                          OTP is valid for: {formatTime(otpTimer)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          style={{ color: "#E53935", fontWeight: "bold" }}
                        >
                          OTP has expired. Please request a new one.
                          <div>
                            OTP has expired. Please request a new one.{" "}
                            <span
                              onClick={forDoctorSignup}
                              style={{ color: "#4da6ff", cursor: "pointer" }}
                            >
                              Resend OTP
                            </span>{" "}
                          </div>
                        </Typography>
                      )}
                    </Box>

                    <LogButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={verifyOtp}
                      disabled={isOtpVerifying}
                      style={{
                        fontWeight: "bold",
                        height: "50px",
                        fontFamily: "Montserrat",
                        marginTop: "20px",
                        borderRadius: "10px",
                        background: "#133682",
                        color: "#FFFFFF",
                      }}
                    >
                      {isOtpVerifying ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Verify OTP"
                      )}
                    </LogButton>
                  </Box>
                )}
              </Box>
            </Card>
          </Box>
        </ThemeProvider>
      </Box>
    </React.Fragment>
  );
};

export default DocSignup;
