import {
  CircularProgress,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  createTheme,
  ThemeProvider,
  TableHead,
  TextField,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  searchHospital,
  sendRequest,
  getSentRequest,
  deleteRequest,
  doctorDetailsGet,
} from "../../Service/Services";
import toast from "react-hot-toast";
import CarouselComponent from "./App";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const JoinHospital = () => {

  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {
      fontFamily: "Montserrat",
    },
  });

  const [searchText, setSearchText] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [hospitals, setHospitals] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editid, setEditid] = useState(null);
  const [open, setOpen] = useState(null);
  const [ownerHospital, setOwnerHospital] = useState([]);

  const [hasPermission, setHasPermission] = useState(null); // Track permission status
  const getOwnerHospitals = async () => {
    try {
        const response = await doctorDetailsGet();
        if (response?.data.status) {
          const doctorData = response.data?.data.hospitals;
          let temp = [];
          for(let i=0;i<doctorData.length;i++){
            temp.push(doctorData[i].hospitalName);
          }
          console.log(temp);
          
          setOwnerHospital(temp);
        }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetHospitals = async (searchTerm) => {
    const responseJson = await searchHospital(searchTerm);
    if (responseJson.data.status) {
      const data = responseJson.data.data.filter((s) => !(ownerHospital.filter((h) => h === s.hospitalName).length > 0))
      console.log(responseJson.data.data);
      
      console.log(ownerHospital);
      
      const result = data.map((key) => ({
        value: key._id,
        label: `${key.hospitalName}, ${key.hospitalLocation}`,
      }));
      setHospitals(result);
    } else {
      setHospitals([]); // Clear hospitals if no results
    }
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!selectedHospital) {
      setLoading(false);
      toast.error("Please select a hospital to send the request");
      return;
    }
    const requestBody = { hospitalId: selectedHospital?.value };
    const responseJson = await sendRequest(requestBody);
    if (responseJson.data.status) {
      toast.success(responseJson.data.message);
    } else {
      toast.error(responseJson.data.message);
    }
    setLoading(false);
    await getSendRequest();
  };

  const getSendRequest = async () => {
    const responseJson = await getSentRequest();
    if (responseJson.data.status) {
      setRequests(responseJson.data.data);
    }
    console.log(responseJson.data.data);
    
  };
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setEditid(id);
  };

  useEffect(() => {
    const fetchDoctorDetails = async () => {
          try {
            const response = await doctorDetailsGet(); // Fetch doctor details
            //console.log("API Response:", response); // Log full response
            //console.log("Doctor Data:", response?.data?.data); // Log doctor object
              
            if (response?.data?.status) {
              const permissions = response?.data?.data?.permissions || {}; // Ensure permissions exist
              //console.log("Doctor Permissions:", permissions);
              
              setHasPermission(permissions.joinHospital); // Check if `joinHospital` permission is `true`
            } else {
              console.log("Permissions not found in response");
              setHasPermission(false); // Default to false if data is missing
            }
          } catch (error) {
            console.error("Error fetching doctor details:", error);
            setHasPermission(false);
          }
        };
              
        fetchDoctorDetails();
    getSendRequest();
    getOwnerHospitals();
    handleGetHospitals(searchText);
  }, [searchText]);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleDelete = async()=>{
    if(editid === null){
      return;
    }
    try {
      const res = await deleteRequest(editid);
      toast.success("deleted sucessfully!");
      getSendRequest();
      console.log(res);
    } catch (error) {
      toast.error("something went wrong!");
      console.log(error);
    }
  }

  if (hasPermission === null) {
          return <Typography>Loading...</Typography>;
        }
      
        if (!hasPermission) {
          return (
            <React.Fragment>
              <Typography variant="h5" color="error" align="center" sx={{ mt: 5 }}>
                You don't have permission for this page.
              </Typography>
              </React.Fragment>
          );
        }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {/* <ResponsiveDiv>
          <CarouselComponent />
        </ResponsiveDiv> */}

        <Box
          display={"flex"}
          marginTop={"0.5rem"}
          justifyContent={"space-around"}
        >
          <Box style={{ width: "80%", position: "relative" }}>
            <TextField
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                handleGetHospitals(e.target.value); // Fetch suggestions as user types
              }}
              placeholder="Search for a hospital..."
              fullWidth
            />
            {searchText && hospitals.length > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  width: "100%",
                  border: "1px solid #ccc",
                  backgroundColor: "white",
                  borderRadius: "4px",
                  zIndex: 10,
                }}
              >
                {hospitals.map((hospital) => (
                  <Box
                    key={hospital.value}
                    onClick={() => {
                      setSelectedHospital(hospital);
                      setSearchText(hospital.label); // Optionally set search text to selected value
                      setHospitals([]); // Clear suggestions after selection
                    }}
                    sx={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    {hospital.label}
                  </Box>
                ))}
                {hospitals.length === 0 && (
                  <Typography
                    sx={{
                      padding: "8px",
                      textAlign: "center",
                      color: "#999",
                    }}
                  >
                    No hospitals found
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          <Button
            variant="contained"
            color="success"
            onClick={handleSendRequest}
            style={{ width: "10%" }}
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Request"
            )}
          </Button>
        </Box>
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
        <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>HOSPITAL NAME</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requests.length > 0 ? (
                  requests.map((request) => (
                    <TableRow key={request._id}>
                      <TableCell>{request.hospitalname}</TableCell>
                      <TableCell>{request.status === "Pending" ? "InActive" : "Active" }</TableCell>
                      
                      <TableCell>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(e) => handleOpenMenu(e, request._id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No treatments found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Popover */}
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
          >
            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                handleDelete();
                handleCloseMenu();
              }} 
            >
              <DeleteIcon />
              Delete
            </MenuItem>
          </Popover>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default JoinHospital;
