const countryCallingCodesMap = new Map([
  ["IN", ["India", "+91"]],
  ["AD", ["Andorra", "+376"]],
  ["AR", ["Argentina", "+54"]],
  ["AU", ["Australia", "+61"]],
  ["AZ", ["Azerbaijan", "+994"]],
  ["BE", ["Belgium", "+32"]],
  ["BJ", ["Benin", "+229"]],
  ["BA", ["Bosnia and Herzegovina", "+387"]],
  ["BR", ["Brazil", "+55"]],
  ["BG", ["Bulgaria", "+359"]],
  ["BF", ["Burkina Faso", "+226"]],
  ["CM", ["Cameroon", "+237"]],
  ["CA", ["Canada", "+1"]],
  ["CL", ["Chile", "+56"]],
  ["CN", ["China", "+86"]],
  ["CO", ["Colombia", "+57"]],
  ["CK", ["Cook Islands", "+690"]],
  ["CK", ["Cook Islands", "+682"]],
  ["HR", ["Croatia", "+385"]],
  ["CZ", ["Czech Republic", "+420"]],
  ["CD", ["Democratic Republic of the Congo", "+243"]],
  ["DK", ["Denmark", "+45"]],
  ["DJ", ["Djibouti", "+253"]],
  ["DJ", ["Djibouti", "+253"]],
  ["EG", ["Egypt", "+20"]],
  ["EE", ["Estonia", "+372"]],
  ["SZ", ["Eswatini", "+268"]],
  ["SZ", ["Eswatini", "+268"]],
  ["FI", ["Finland", "+358"]],
  ["FR", ["France", "+33"]],
  ["GA", ["Gabon", "+241"]],
  ["DE", ["Germany", "+49"]],
  ["GW", ["Guinea-Bissau", "+245"]],
  ["HU", ["Hungary", "+36"]],
  ["IS", ["Iceland", "+354"]],
  ["IE", ["Ireland", "+353"]],
  ["IL", ["Israel", "+972"]],
  ["IT", ["Italy", "+39"]],
  ["CI", ["Ivory Coast", "+225"]],
  ["JP", ["Japan", "+81"]],
  ["JO", ["Jordan", "+962"]],
  ["KZ", ["Kazakhstan", "+7"]],
  ["KE", ["Kenya", "+254"]],
  ["KI", ["Kiribati", "+686"]],
  ["LV", ["Latvia", "+371"]],
  ["LI", ["Liechtenstein", "+423"]],
  ["LT", ["Lithuania", "+370"]],
  ["LU", ["Luxembourg", "+352"]],
  ["MG", ["Madagascar", "+261"]],
  ["MY", ["Malaysia", "+60"]],
  ["MR", ["Mauritania", "+222"]],
  ["MX", ["Mexico", "+52"]],
  ["MC", ["Monaco", "+377"]],
  ["NA", ["Namibia", "+264"]],
  ["NR", ["Nauru", "+674"]],
  ["NL", ["Netherlands", "+31"]],
  ["NZ", ["New Zealand", "+64"]],
  ["NO", ["Norway", "+47"]],
  ["PK", ["Pakistan", "+92"]],
  ["PH", ["Philippines", "+63"]],
  ["PL", ["Poland", "+48"]],
  ["PT", ["Portugal", "+351"]],
  ["CG", ["Republic of the Congo", "+242"]],
  ["RO", ["Romania", "+40"]],
  ["RU", ["Russia", "+7"]],
  ["RW", ["Rwanda", "+250"]],
  ["WS", ["Samoa", "+685"]],
  ["SA", ["Saudi Arabia", "+966"]],
  ["SA", ["Saudi Arabia", "+966"]],
  ["SG", ["Singapore", "+65"]],
  ["SK", ["Slovakia", "+421"]],
  ["SI", ["Slovenia", "+386"]],
  ["SO", ["Somalia", "+252"]],
  ["SO", ["Somalia", "+252"]],
  ["ZA", ["South Africa", "+27"]],
  ["KR", ["South Korea", "+82"]],
  ["ES", ["Spain", "+34"]],
  ["SE", ["Sweden", "+46"]],
  ["CH", ["Switzerland", "+41"]],
  ["TJ", ["Tajikistan", "+992"]],
  ["TZ", ["Tanzania", "+255"]],
  ["TH", ["Thailand", "+66"]],
  ["TR", ["Turkey", "+90"]],
  ["TV", ["Tuvalu", "+695"]],
  ["UG", ["Uganda", "+256"]],
  ["UA", ["Ukraine", "+380"]],
  ["GB", ["United Kingdom", "+44"]],
  ["US", ["USA", "+1"]],
  ["VU", ["Vanuatu", "+678"]],
  ["VU", ["Vanuatu", "+678"]],
  ["VN", ["Vietnam", "+84"]],
  ["YE", ["Yemen", "+967"]],
  ["ZW", ["Zimbabwe", "+263"]],
]);

export default countryCallingCodesMap;
