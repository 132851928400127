import React, { useEffect, useState } from "react";
import { getDoctorLeads,doctorDetailsGet } from "../../Service/Services";
import { AnalyticsWrapper } from "./styles/DoctorLeadStyles";
import leadsImage from "../../assets/leads.png";
import { FlexCol } from "../../styles/CommonStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { FormControl, MenuItem, Box, Typography, Button, Dialog, DialogTitle, DialogContent, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";

const DoctorLeads = () => {
  const [leads, setLeads] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [hasPermission, setHasPermission] = useState(null); // Track permission status

  const fetchDoctorLeads = async () => {
    const response = await getDoctorLeads();
    console.log("response",response)
    if (response?.data.status) {
      const updatedLeads = response.data.data.map((lead) => ({
        ...lead,
        appointmentStatus: lead.appointmentStatus || "Change Status",
        statusChangeDate: lead.statusChangeDate || [],
      }));
      setLeads(updatedLeads);
    }
  };

  useEffect(() => {
    const fetchDoctorDetails = async () => {
          try {
            const response = await doctorDetailsGet(); // Fetch doctor details
            console.log("API Response:", response); // Log full response
            console.log("Doctor Data:", response?.data?.data); // Log doctor object
      
            if (response?.data?.status) {
              const permissions = response?.data?.data?.permissions || {}; // Ensure permissions exist
              console.log("Doctor Permissions:", permissions); // Debugging log
      
              setHasPermission(permissions.leads); // Check if `leads` permission is `true`
            } else {
              console.log("Permissions not found in response");
              setHasPermission(false); // Default to false if data is missing
            }
          } catch (error) {
            console.error("Error fetching doctor details:", error);
            setHasPermission(false);
          }
        };
      
        fetchDoctorDetails();
    fetchDoctorLeads();
  }, []);

  const handleStatusChange = (value, id) => {
    setLeads((prevLeads) =>
      prevLeads.map((lead) =>
        lead._id === id
          ? {
              ...lead,
              appointmentStatus: value,
              statusChangeDate: [
                ...lead.statusChangeDate,
                { status: value, date: new Date().toLocaleString() },
              ],
            }
          : lead
      )
    );
  };

  const handleOpenModal = (leadId) => {
    const lead = leads.find((lead) => lead._id === leadId);
    setSelectedLead(lead);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedLead(null);
  };

  console.log("hasPermission",hasPermission)
    if (hasPermission === null) {
      return <Typography>Loading...</Typography>;
    }
  
    if (!hasPermission) {
      return (
        <React.Fragment>
          <Typography variant="h5" color="error" align="center" sx={{ mt: 5 }}>
            You don't have permission for this page.
          </Typography>
          </React.Fragment>
      );
    }
  
  return (
    <FlexCol
      gap={20}
      sx={{
        padding: 2,
        '@media (max-width: 600px)': {
          padding: 1,
        },
      }}
    >
      <AnalyticsWrapper>
        <img src={leadsImage} alt="" />
        <h3 className="stats">{leads.length}</h3>
        <h6 className="title">
          {leads.length > 0 ? "Total Leads" : "No leads"}
        </h6>
      </AnalyticsWrapper>
      {leads.length > 0 && (
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Patient Name</TableCell>
                <TableCell>Email ID</TableCell>
                <TableCell>Phone No</TableCell>
                <TableCell>Appointment Status</TableCell>
                 
              </TableRow>
            </TableHead>
            <TableBody>
              {leads.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        value={row.appointmentStatus}
                        onChange={(e) =>
                          handleStatusChange(e.target.value, row._id)
                        }
                        sx={{
                          fontSize: "0.85rem",
                          height: "2.5rem",
                          width:"9.5rem",
                          "& .MuiSelect-select": {
                            paddingY: "12px",
                          },
                          "& .MuiSelect-icon": {
                            fontSize: "1rem",
                          },
                        }}
                      >
                        <MenuItem value="Change Status">Change Status</MenuItem>
                        <MenuItem value="Not Willing">Not Willing</MenuItem>
                        <MenuItem value="Booked Appointment">Booked Appointment</MenuItem>
                        <MenuItem value="Consultation Over">Consultation Over</MenuItem>
                        <MenuItem value="Not able to Contact">Not able to Contact</MenuItem>
                        <MenuItem value="Cancelled Appointment">Cancelled Appointment</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleOpenModal(row._id)}
                      variant="text"
                      color="success"
                      sx={{
                        '@media (max-width: 600px)': {
                          fontSize: '0.65rem',
                        },
                      }}
                    >
                      Status
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog 
        open={openModal} 
        onClose={handleCloseModal}
        PaperProps={{
          sx: { 
            minWidth: '80vw', // Adjusted for better fit on mobile
            maxWidth: '90vw',
            maxHeight: '80vh', // Prevent overflow on smaller screens
            overflowY: 'auto', // Allow scrolling if content is too long
          },
        }}
        sx={{
          '& .MuiDialog-paper': {
            overflowY: 'auto',
          },
        }}
      >
        <DialogTitle>
          Status Change History
        </DialogTitle>
        <DialogContent>
          {selectedLead ? (
            selectedLead.statusChangeDate.length > 0 ? (
              selectedLead.statusChangeDate.map((change, index) => (
                <Box key={index} mb={1} display="flex" flexDirection="column" alignItems="start">
                  <Typography variant="body2">
                    "{selectedLead.doctorName}" changed {selectedLead.name}'s request "{change.status}"
                  </Typography>
                  <Box sx={{ ml: "auto" }}>
                    <Typography variant="body2" sx={{ color: grey[500], fontSize: "12px" }}>
                      {change.date}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                </Box>
              ))
            ) : (
              <Typography variant="body2">No status changes recorded.</Typography>
            )
          ) : (
            <Typography variant="body2">No patient selected.</Typography>
          )}
        </DialogContent>
        <Button 
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            '@media (max-width: 600px)': {
              fontSize: '0.75rem',
              padding: '6px 12px',
            },
          }}
          color="error"
          variant="contained"
        >
          Close
        </Button>
      </Dialog>
    </FlexCol>
  );
};

export default DoctorLeads;
