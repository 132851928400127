import React, { useState } from "react";
import {
  Box,
  Container,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/Logo.png";
import { doctorForgotPassword } from "../Service/Services"; // Ensure this function is defined and imported
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {
      fontFamily: "Montserrat",
    },
  });

  const navigate = useNavigate();
  const [state, setState] = useState(""); // Email input state
  const [loading, setLoading] = useState(false); // Loading state for form submission

  const forSubmit = async () => {
    if (state) {
      const data = { email: state };
      setLoading(true); // Set loading to true when starting the request

      try {
        const responseJson = await doctorForgotPassword(data);
        if (responseJson.data.status) {
          toast.success(responseJson.data.message);
        } else {
          toast.error(responseJson.data.message);
        }
        setState("");
        navigate("/doctor/login");
      } catch (error) {
        toast.error("An error occurred");
      }
      setLoading(false); // Reset loading state after the request
    } else {
      toast.error("Please enter an email");
    }
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Container>
          <Box
            component={"div"}
            sx={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Box
              component="form"
              sx={{
                mt: 1,
                borderRadius: "12px",
                padding: "40px 60px",
                boxShadow: "0px 10px 40px rgba(0,0,0,0.1)",
                backgroundColor: "white",
                maxWidth: "400px",
                width: "100%",
              }}
            >
              <div className="d-flex justify-content-center mb-4">
                <img src={Logo} style={{ width: 200 }} alt="Logo_image" />
              </div>
              <div>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    textAlign: "center",
                    fontWeight: 800,
                    fontFamily: "Montserrat",
                    fontSize: "1.25rem",
                    color: "#333",
                  }}
                >
                  Forgot your password?
                </Typography>
                <Typography
                  variant="body2"
                  component={"p"}
                  sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    fontSize: "1rem",
                    color: "#666",
                  }}
                >
                  Enter your registered email to reset your password
                </Typography>
              </div>

              {/* Email Input */}
              <TextField
                margin="normal"
                value={state}
                required
                fullWidth
                id="email"
                autoComplete="off"
                type="email"
                placeholder="Email Address"
                name="email"
                onChange={(e) => setState(e.target.value)}
                InputProps={{
                  style: {
                    height: "2.8em",
                    fontFamily: "Montserrat",
                  },
                }}
                sx={{
                  mb: 3,
                }}
              />

              {/* Submit Button */}
              <button
                onClick={forSubmit}
                type="button"
                className="w-full rounded-md bg-blue-500 text-white hover:bg-blue-600 transition-all duration-150 ease-in-out px-8 py-3 capitalize focus:outline-none shadow-lg"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </button>

              {/* Back Button */}
              <div
                onClick={() => navigate("/doctor/login")}
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "#133680",
                  cursor: "pointer",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Back to Login</span>
              </div>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default ForgotPassword;
