import React, { useState } from "react";
import {
  Box,
  Container,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import Logo from "../../assets/Logo.png";
import { UploadButton } from "../../CustomStyles/Styles";
import { doctorForgotPassword } from "../../Service/Services";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {
      fontFamily: "Montserrat",
    },
  });

  const [state, setState] = useState("");

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const forSubmit = async () => {
    if (!state) {
      toast.error("Please enter your email.");
      return;
    }

    if (!isValidEmail(state)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const data = { email: state };
    try {
      const responseJson = await doctorForgotPassword(data);
      if (responseJson.data.status) {
        toast.success(responseJson.data.message);
      } else {
        toast.error(responseJson.data.message);
      }
      setState("");
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              forSubmit();
            }}
            sx={{
              mt: 1,
              borderRadius: "12px", // Rounded corners
              padding: "40px 60px", // Add more padding for spacious feel
              boxShadow: "0px 10px 40px rgba(0,0,0,0.1)", // Shadow for modern look
              backgroundColor: "white", // White background for form
              maxWidth: "400px", // Restrict form width
              width: "100%", // Full width on mobile
            }}
            className="col-lg-5 col-md-6 col-sm-8 col-12"
          >
            <div className="d-flex justify-content-center mb-4">
              <Box component="img" src={Logo} style={{ width: 200 }} />
            </div>
            <div>
              <Typography
                variant="body1"
                component="p"
                sx={{
                  textAlign: "center",
                  fontWeight: 800,
                  fontFamily: "Montserrat",
                  fontSize: "1.25rem",
                  color: "#333", // Darker color for contrast
                }}
              >
                Forgot your password?
              </Typography>
              <Typography
                variant="body2"
                component="p"
                sx={{
                  textAlign: "center",
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                  color: "#666", // Lighter color for secondary text
                }}
              >
                Enter your registered email to reset your password
              </Typography>
            </div>
            <TextField
              margin="normal"
              value={state}
              required
              fullWidth
              id="email"
              autoComplete="off"
              type="email"
              placeholder="Email Address"
              name="email"
              onChange={(e) => setState(e.target.value)}
              InputProps={{
                style: {
                  height: "2.8em", // Taller input for better interaction
                  fontFamily: "Montserrat",
                },
              }}
              sx={{
                mb: 3, // Space below the input
              }}
            />
            {/* Submit Button */}
            <UploadButton
              fullWidth
              type="submit"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "bold",
                backgroundColor: "#133680", // Matching primary color
                color: "white", // White text
                padding: "10px 20px", // More padding for the button
                borderRadius: "8px", // Rounded corners for the button
                textAlign: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
              }}
            >
              Submit
            </UploadButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ForgotPassword;
