import React, { useEffect, useState } from "react";
import DocProfile from "./DocProfile";
import { doctorDetailsGet } from "../../Service/Services";
import { Typography } from "@mui/material";
const DoctorMainProfile = () => {

  const [hasPermission, setHasPermission] = useState(null); // Track permission status

  useEffect(() => {
      const fetchDoctorDetails = async () => {
                try {
                  const response = await doctorDetailsGet(); // Fetch doctor details
                  //console.log("API Response:", response); // Log full response
                  //console.log("Doctor Data:", response?.data?.data); // Log doctor object
                        
                  if (response?.data?.status) {
                    const permissions = response?.data?.data?.permissions || {}; // Ensure permissions exist
                    //console.log("Doctor Permissions:", permissions);
                        
                    setHasPermission(permissions.profile); // Check if `profile` permission is `true`
                  } else {
                    console.log("Permissions not found in response");
                    setHasPermission(false); // Default to false if data is missing
                  }
                } catch (error) {
                  console.error("Error fetching doctor details:", error);
                  setHasPermission(false);
                }
              };
                        
              fetchDoctorDetails();
    }, []);

  if (hasPermission === null) {
              return <Typography>Loading...</Typography>;
            }
          
            if (!hasPermission) {
              return (
                <React.Fragment>
                  <Typography variant="h5" color="error" align="center" sx={{ mt: 5 }}>
                    You don't have permission for this page.
                  </Typography>
                  </React.Fragment>
              );
            }
  return (
    <React.Fragment>
      <DocProfile />
    </React.Fragment>
  );
};

export default DoctorMainProfile;
