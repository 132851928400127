import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import CircularProgress from "@mui/material/CircularProgress";
import { LogButton } from "../../CustomStyles/Styles"; // Adjust the import path
import LogoImage from "../../assets/image.png"; // Adjust the import path
import { Link, useNavigate } from "react-router-dom";
import { doctorSignup, sendOtp } from "../../Service/Services";
import { toast, Toaster } from "react-hot-toast";
import countryCallingCodesMap from "./countryCallingCodesMap";

const DocmobileSignup = () => {
  const [doctor, setDoctor] = useState({
    email: "",
    mobile: null,
    password: "",
    confirmpassword: "",
  });
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [isOtpVerifying, setIsOtpVerifying] = useState(false);
  const [otpTimer, setOtpTimer] = useState(600);
  const [otpTimerRunning, setOtpTimerRunning] = useState(false);
  const [otp, setOTP] = useState("");
  const navigate = useNavigate();
  const [hasAlphabet, setHasAlphabet] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [isValidLen, setIsValidLen] = useState(false);

  const options = [...countryCallingCodesMap.keys()];

  useEffect(() => {
    let timer;
    if (otpTimerRunning) {
      timer = setInterval(() => {
        setOtpTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setOtpTimerRunning(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpTimerRunning]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const forCountryCode = (e) => {
    setCountryCode(e.target.value);
  };

  const forMobileNumber = (e) => {
    setMobileNumber(e.target.value);
  };

  const doctorChange = (e) => {
    const { name, value } = e.target;
    setDoctor({ ...doctor, [name]: value });
  };

  const checkPassword = async (e) => {
    const { name, value } = e.target;
    setDoctor({ ...doctor, [name]: value });

    const hasAlphabet = /[a-zA-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSymbol = /[^a-zA-Z0-9]/.test(value);
    const isValidLen = value.length >= 8;

    setHasAlphabet(hasAlphabet);
    setHasNumber(hasNumber);
    setHasSymbol(hasSymbol);
    setIsValidLen(isValidLen);
  };

  const forDoctorSignup = async () => {
    const { email, password, confirmpassword } = doctor;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && password && confirmpassword) {
      if (!emailRegex.test(email)) {
        toast.error("Please enter a valid email address");
        return;
      }

      setIsLoading(true);
      if (!(hasAlphabet && hasNumber && hasSymbol && isValidLen)) {
        toast.error("Password must be strong");
        return;
      }
      if (password !== confirmpassword) {
        toast.error("Password does not match");
        setIsLoading(false);
      } else {
        try {
          const response = await sendOtp({ email });
          setOTP(response.data.otp);
          setIsOtpSent(true);
          setOtpTimer(600);
          setOtpTimerRunning(true);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("An error occurred while sending OTP");
          setIsLoading(false);
        }
      }
    } else {
      toast.error("All fields are required!");
      if (password !== confirmpassword) {
        toast.error("Password does not match");
      }
    }
  };

  const verifyOtp = async () => {
    if (otpInput) {
      setIsOtpVerifying(true);
      if (otpInput === otp) {
        try {
          const { email, password } = doctor;
          const data = {
            email,
            otp: otpInput,
            password,
            countrycode: countryCode,
            mobile: mobileNumber,
          };
          const response = await doctorSignup(data);
          if (response?.data) {
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("type", "doctors");
            navigate("/doctor/profile");
            toast.success("Account created successfully!");
          } else {
            toast.error("Failed to verify OTP");
          }
          setIsOtpVerifying(false);
        } catch (error) {
          toast.error("error.response.data.message");
        }
      } else {
        toast.error("OTP does not match");
      }
    } else {
      toast.error("Please enter the OTP");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <React.Fragment>
      <Box
        style={{
          fontFamily: "Montserrat",
          fontWeight: "bold",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          backgroundColor: "#D7E7FF",
        }}
      >
        <Box
          component="img"
          src={LogoImage}
          style={{
            top: "64px",
            left: "calc(50% - 170px/2 - 84.5px)",
            width: "170px",
            height: "47px",
          }}
        />
        <Card
          sx={{
            p: 2,
            width: "100%",
            maxWidth: "360px",
            borderRadius: "16px",
            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Typography
            variant="h5"
            component="div"
            style={{
              textAlign: "center",
              fontWeight: "600",
              marginBottom: "16px",
              fontFamily: "Poppins",
              color: "#2D3748",
            }}
          >
            Create an Account
          </Typography>
          <Box component="form" sx={{ width: "100%" }}>
            {!isOtpSent ? (
              <>
                {/* Email Field */}
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="Enter your email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <PersonIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={doctorChange}
                  name="email"
                />

                {/* Mobile Number Field */}
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="Enter your mobile number"
                  value={mobileNumber}
                  onChange={forMobileNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <select
                          value={countryCode}
                          onChange={forCountryCode}
                          style={{
                            border: "none",
                            background: "transparent",
                            outline: "none",
                            fontFamily: "Montserrat",
                            cursor: "pointer",
                          }}
                        >
                          {options.map((option) => (
                            <option
                              key={option}
                              value={countryCallingCodesMap.get(option)[1]}
                            >
                              {countryCallingCodesMap.get(option)[1]} ({option})
                            </option>
                          ))}
                        </select>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <PhoneIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {/* Password Field */}
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={checkPassword}
                  name="password"
                />

                {/* Password Requirements */}
                {doctor.password.length >= 1 && (
                  <Box mt={2}>
                    <Typography variant="body2" fontWeight="bold">
                      Password Requirements:
                    </Typography>
                    <ul
                      style={{
                        paddingLeft: "1.5rem",
                        margin: 0,
                        fontSize: "0.85rem",
                      }}
                    >
                      <li
                        style={{ color: hasAlphabet ? "#4CAF50" : "#E53935" }}
                      >
                        Contains at least one alphabet
                      </li>
                      <li style={{ color: hasNumber ? "#4CAF50" : "#E53935" }}>
                        Contains at least one number
                      </li>
                      <li style={{ color: hasSymbol ? "#4CAF50" : "#E53935" }}>
                        Contains at least one special character (e.g., @, #, $,
                        etc.)
                      </li>
                      <li style={{ color: isValidLen ? "#4CAF50" : "#E53935" }}>
                        At least 8 characters long
                      </li>
                    </ul>
                  </Box>
                )}

                {/* Confirm Password Field */}
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type={showPassword ? "text" : "password"}
                  placeholder="Re-enter your password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={doctorChange}
                  name="confirmpassword"
                />

                {/* Sign-Up Button */}
                <button
                  style={{
                    width: "100%",
                    padding: "12px",
                    marginTop: "16px",
                    backgroundColor: "#3182CE",
                    color: "#FFF",
                    fontSize: "16px",
                    fontWeight: "600",
                    borderRadius: "8px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={forDoctorSignup}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Create Account"
                  )}
                </button>

                {/* Terms and Conditions */}
                <Box mt={3}>
                  <Typography
                    variant="body2"
                    style={{
                      color: "#718096",
                      fontSize: "14px",
                      textAlign: "center",
                      lineHeight: "1.5",
                    }}
                  >
                    By signing up, you agree to our{" "}
                    <Link
                      to="/privacypolicy"
                      style={{
                        color: "#3182CE",
                        textDecoration: "none",
                        fontWeight: "600",
                      }}
                    >
                      Terms and Conditions
                    </Link>
                    .
                  </Typography>
                </Box>
                {/* Additional Links */}
                <Box mt={3} textAlign="center">
                  <Typography
                    variant="body2"
                    style={{
                      color: "#4A5568",
                      fontSize: "14px",
                      marginBottom: "8px",
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      to="/doctor/login"
                      style={{
                        color: "#3182CE",
                        textDecoration: "none",
                        fontWeight: "600",
                      }}
                    >
                      Sign In
                    </Link>
                  </Typography>
                </Box>
              </>
            ) : (
              <Box textAlign="center">
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    margin: "20px 0",
                    fontSize: "16px",
                    color: "#4A4A4A",
                  }}
                >
                  We have sent an OTP to your email. Please enter it below to
                  verify your account.
                </Typography>

                <Box mt={2}>
                  {otpTimer > 0 ? (
                    <Typography
                      variant="body2"
                      style={{ color: "#4CAF50", fontWeight: "bold" }}
                    >
                      OTP is valid for: {formatTime(otpTimer)}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      style={{ color: "#E53935", fontWeight: "bold" }}
                    >
                      OTP has expired. Please request a new one.
                      <div>
                        OTP has expired. Please request a new one.{" "}
                        <span
                          onClick={forDoctorSignup}
                          style={{ color: "#4da6ff", cursor: "pointer" }}
                        >
                          Resend OTP
                        </span>{" "}
                      </div>
                    </Typography>
                  )}
                </Box>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="otp"
                  label="OTP"
                  name="otp"
                  placeholder="Enter OTP"
                  value={otpInput}
                  onChange={(e) => setOtpInput(e.target.value)}
                />

                <button
                  style={{
                    width: "100%",
                    padding: "12px",
                    marginTop: "16px",
                    backgroundColor: "#133682",
                    color: "#FFF",
                    fontSize: "16px",
                    fontWeight: "600",
                    borderRadius: "8px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={verifyOtp}
                  disabled={isOtpVerifying}
                >
                  {isOtpVerifying ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default DocmobileSignup;
