// import { useState, useEffect } from "react";
// import { adminGetAllDoctors } from "../Service/Services"; // Import API function

// const options = [
//   "Profile",
//   "Hospital Profile",
//   "Doctor Request",
//   "Join Hospital",
//   "Treatments",
//   "Leads",
//   "Videos",
// ];

// export default function DoctorAccess() {
//   const [doctors, setDoctors] = useState([]); // Store fetched doctors
//   const [selected, setSelected] = useState([]);
//   const [page, setPage] = useState(0);
//   const doctorsPerPage = 5;

//   // Fetch doctors from API
//   useEffect(() => {
//     const fetchDoctors = async () => {
//       try {
//         const res = await adminGetAllDoctors(100, 0, "", 1, 1); // Fetch doctors
//         if (res?.data?.data) {
//           setDoctors(res.data.data); // Store real doctors
//           setSelected(
//             res.data.data.map(() => ({
//               Profile: false,
//               "Hospital Profile": false,
//               "Doctor Request": false,
//               "Join Hospital": false,
//               Treatments: false,
//               Leads: false,
//               Videos: false,
//             }))
//           );
//         }
//       } catch (error) {
//         console.error("Error fetching doctors:", error);
//       }
//     };

//     fetchDoctors();
//   }, []);

//   const handleCheckboxChange = (doctorIndex, option) => {
//     setSelected((prev) => {
//       const newSelected = [...prev];
//       const doctorPermissions = { ...newSelected[doctorIndex] };

//       const controlOptions = [
//         "Profile",
//         "Hospital Profile",
//         "Doctor Request",
//         "Join Hospital",
//         "Leads",
//       ];

//       doctorPermissions[option] = !doctorPermissions[option];

//       if (controlOptions.includes(option)) {
//         if (doctorPermissions[option]) {
//           controlOptions.forEach((opt) => (doctorPermissions[opt] = true));
//         } else {
//           controlOptions.forEach((opt) => (doctorPermissions[opt] = false));
//         }
//       }

//       newSelected[doctorIndex] = doctorPermissions;
//       return newSelected;
//     });
//   };

//   return (
//     <div className="p-4">
//       {doctors.slice(page * doctorsPerPage, (page + 1) * doctorsPerPage).map((doctor, doctorIndex) => {
//         const actualIndex = page * doctorsPerPage + doctorIndex;
//         return (
//           <div key={doctor._id} className="border p-3 mb-3 rounded-lg shadow-md">
//             <h3 className="font-bold text-lg mb-2">{doctor.firstname} {doctor.lastname}</h3>
//             <div className="flex flex-wrap gap-4">
//               {options.map((option, optionIndex) => (
//                 <label key={optionIndex} className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={selected[actualIndex]?.[option] || false}
//                     onChange={() => handleCheckboxChange(actualIndex, option)}
//                     className="w-4 h-4"
//                   />
//                   <span>{option}</span>
//                 </label>
//               ))}
//             </div>
//           </div>
//         );
//       })}

//       {/* Pagination Controls */}
//       <div className="flex justify-center mt-4 space-x-4">
//         <button
//           onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
//           disabled={page === 0}
//           className={`px-4 py-2 rounded-md shadow-md ${
//             page === 0 ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"
//           }`}
//         >
//           &lt; Previous
//         </button>
//         <button
//           onClick={() => setPage((prev) => Math.min(prev + 1, Math.ceil(doctors.length / doctorsPerPage) - 1))}
//           disabled={(page + 1) * doctorsPerPage >= doctors.length}
//           className={`px-4 py-2 rounded-md shadow-md ${
//             (page + 1) * doctorsPerPage >= doctors.length ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"
//           }`}
//         >
//           Next &gt;
//         </button>
//       </div>
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { adminDoctorDetailsGet, adminDoctorDetailsUpdate } from "../Service/Services";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

const DoctorAccess = () => {
  const [form, setForm] = useState({
    _id: "",
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    city: "",
    country: "",
    specilization: [],
  });

  const [permissions, setPermissions] = useState({
    profile: true,
    hospitalProfile: true,
    doctorRequest: true,
    joinHospital: true,
    leads: true,
    treatments: false,
    videos: false,
  });

  const { id } = useParams();

  useEffect(() => {
    forDoctorGet();
  }, [id]);

  // const forDoctorGet = async () => {
  //   const response = await adminDoctorDetailsGet(id);
  //   if (response?.data?.status && response.data?.data) {
  //     setForm(response.data.data);
  //     if (response.data.data.permissions) {
  //       setPermissions(response.data.data.permissions);
  //     }
  //   } else {
  //     toast.error(response?.data.message || "Failed to fetch doctor details.");
  //   }
  // };

  const forDoctorGet = async () => {
    try {
      const response = await adminDoctorDetailsGet(id);
  
      console.log("Fetched Data:", response?.data?.data); // Debugging
  
      if (response?.data?.status && response.data?.data) {
        console.log("Full API Response:", response.data);
        console.log("Permissions from API:", response.data.data.permissions); // Debugging permissions
  
        setForm(response.data.data);
  
        // 🔹 Merge fetched permissions with default ones to avoid missing keys
        setPermissions((prevPermissions) => ({
          ...prevPermissions,
          ...(response.data.data.permissions || {}), // Use fetched permissions if available
        }));
      } else {
        toast.error(response?.data?.message || "Failed to fetch doctor details.");
      }
    } catch (error) {
      console.error("Error fetching doctor details:", error);
      toast.error("Error fetching doctor details.");
    }
  };
  
  const handlePermissionChange = (event) => {
    const { name, checked } = event.target;
    setPermissions((prev) => ({ ...prev, [name]: checked }));
  };

  // const handleSave = async () => {
  //   try {
  //     const response = await adminDoctorDetailsUpdate(id, { permissions });
  //     console.log("response",response)
  //     if (response?.data?.status) {
  //       toast.success("Permissions saved successfully!");
  //     } else {
  //       toast.error(response?.data?.message || "Failed to save permissions.");
  //     }
  //   } catch (error) {
  //     toast.error("An error occurred while saving permissions.");
  //   }
  // };

  const handleSave = async () => {
    try {
      const updatedData = { ...form, permissions };
  
      console.log("Saving Data:", updatedData); // Debugging
  
      const response = await adminDoctorDetailsUpdate(id, updatedData);
  
      console.log("Server Response:", response); // Debugging
  
      if (response?.data?.status) {
        toast.success("Permissions saved successfully!");
  
        // Don't call forDoctorGet() immediately, update permissions manually
        setPermissions((prevPermissions) => ({ ...prevPermissions }));
  
        // Optional: Call forDoctorGet() after a short delay
        setTimeout(() => {
          forDoctorGet();
        }, 1000);
      } else {
        toast.error(response?.data?.message || "Failed to save permissions.");
      }
    } catch (error) {
      console.error("Error saving permissions:", error);
      toast.error("An error occurred while saving permissions.");
    }
  };
  
  

  return (
    <React.Fragment>
      <Container>
        <Box sx={{ mt: 3, mb: 2, p: 2, border: "1px solid #ddd", borderRadius: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Doctor ID: {form._id}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            {form.firstname} {form.lastname}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {form.email}
          </Typography>
          <Typography variant="body1">
            <strong>Phone:</strong> {form.mobile}
          </Typography>
          <Typography variant="body1">
            <strong>Location:</strong> {form.city}, {form.country}
          </Typography>
          <Typography variant="body1">
            <strong>Department:</strong> {form.specilization?.join(", ")}
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ p: 2, border: "1px solid #ddd", borderRadius: 2 }}>
          {Object.keys(permissions).map((key) => (
            <Grid item xs={12} key={key}>
              <FormControlLabel
                control={<Checkbox checked={permissions[key]} onChange={handlePermissionChange} name={key} />}
                label={key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())} // Format label
              />
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default DoctorAccess;
